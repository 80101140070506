export const responsiveOptions = [
	{
		breakpoint: 1200,
		options: {
			plotOptions: {
				pie: {
					donut: {
						labels: {
							value: {
								fontSize: '16px'
							},
							total: {
								fontSize: '16px'
							}
						}
					}
				}
			}
		}
	},
	{
		breakpoint: 1300,
		options: {
			plotOptions: {
				pie: {
					donut: {
						labels: {
							value: {
								fontSize: '10px'
							},
							total: {
								fontSize: '10px'
							}
						}
					}
				}
			}
		}
	},
	{
		breakpoint: 1600,
		options: {
			plotOptions: {
				pie: {
					donut: {
						labels: {
							value: {
								fontSize: '12px'
							},
							total: {
								fontSize: '12px'
							}
						}
					}
				}
			}
		}
	},
	{
		breakpoint: 1700,
		options: {
			plotOptions: {
				pie: {
					donut: {
						labels: {
							value: {
								fontSize: '10px'
							},
							total: {
								fontSize: '10px'
							}
						}
					}
				}
			}
		}
	}
];
