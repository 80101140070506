import _first from 'lodash/first';
import _get from 'lodash/get';
import { Instance, types } from 'mobx-state-tree';

import { GroupingData } from '../grouping-data';
import { widgetActions } from './widget.actions';

export type WidgetModel = Instance<typeof Widget>;
export type DetailsQueryModel = Instance<typeof DetailsQuery>;
export const WidgetOption = types.model({
	target: types.maybeNull(types.number),
	units: types.union(types.maybeNull(types.string), types.array(types.string)),
	target_type: types.maybeNull(types.string)
});

export const VisibilityOption = types.model({
	hide_for_partner: types.maybeNull(types.boolean)
});

export const DetailsQuery = types.model({
	query: types.number,
	error_text: types.string,
	warning_text: types.string,
	error_values: types.maybeNull(types.array(types.string)),
	warning_values: types.maybeNull(types.array(types.string))
});

export const Widget = types
	.model({
		id: types.union(types.string, types.number),
		uuid: types.string,
		position: types.maybeNull(types.number),
		title: types.string,
		type: types.number,
		query_id: types.union(types.number, types.array(types.number)),
		trend: types.maybeNull(types.number),
		target_operator: types.maybeNull(types.number),
		options: WidgetOption,
		description: types.maybeNull(types.string),
		grouping_types: types.array(types.string),
		total: types.union(types.maybeNull(types.number), types.array(types.number)),
		grouping_data: types.array(GroupingData),
		visibility_options: types.maybeNull(VisibilityOption),
		details_query: types.maybeNull(DetailsQuery)
	})
	.volatile(self => ({
		isLoading: true,
		pollingId: null
	}))
	.actions(widgetActions)
	.views(self => ({
		get isEmptyData(): boolean {
			const valuesByName = _get(_first(self.grouping_data), 'valuesByName');
			const valuesExists = valuesByName && Object.keys(valuesByName).length;

			const isValuesEmpty =
				valuesByName &&
				Object.values(valuesByName)
					.filter((value): value is number[] => Array.isArray(value))
					.every(valuesArr => valuesArr.every(num => num === 0));

			return (!valuesExists || isValuesEmpty) && !self.total;
		}
	}));
