/// <reference types="@bringg/types" />
import { AirbrakeService, Authentication, DashboardSdk } from '@bringg/frontend-utils/';
import { ConfigLoader } from '@bringg/frontend-utils/';
import { Environment } from '@bringg/frontend-utils/dist/common/types';
import { StorageType } from '@bringg/frontend-utils/dist/services/credentials-storage';
import _noop from 'lodash/noop';
import { configure } from 'mobx';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import initI18n from './config/translation';
import AsyncRequestQueue from './services/async-request-queue';
import { DemoData } from './services/demo-data';
import { createStore } from './stores/helpers/create-store';
import { StoreProvider } from './stores/helpers/store-provider';

configure({ isolateGlobalState: true });

const fakeAirbrake = {
	notify: _noop
};

require('./App.scss');

(async function initStandaloneApp() {
	const envConfig: Environment = await new ConfigLoader().loadWithInjectedFallbackRegions({
		fallbackRegionsConfigPath: 'fallback_configurations/'
	});
	const usedAsIframe = window.location.href.includes('usedAsIframe');
	const appName = usedAsIframe ? 'Bringg Dashboard' : 'Bringg Bi App';
	const demoUserData = new DemoData();
	const requestQueue = new AsyncRequestQueue();
	const isDev = process.env.NODE_ENV === 'development';
	const dashboardSDK = new DashboardSdk({ appName, config: envConfig });
	const authService = new Authentication(StorageType.LocalStorage, dashboardSDK);
	const rootElement = document.getElementById('bringg-bi-root');
	const isQaEnv = window.location.href.includes('qa.bringg.com');

	const airbrakeService =
		!isQaEnv && envConfig.airbrake_project_key && envConfig.airbrake_project_id
			? new AirbrakeService({
					projectKey: envConfig.airbrake_project_key,
					projectId: envConfig.airbrake_project_id,
					environment: envConfig.environment
			  })
			: fakeAirbrake;

	const BringgBi = () => {
		const [rootStore] = useState(() =>
			createStore(
				demoUserData,
				dashboardSDK,
				authService,
				envConfig,
				requestQueue,
				airbrakeService,
				usedAsIframe,
				isDev,
				isQaEnv
			)
		);

		return (
			<StoreProvider value={rootStore}>
				<App />
			</StoreProvider>
		);
	};

	await initI18n();

	// render standalone app only if there is an element
	if (rootElement) {
		ReactDOM.render(<BringgBi />, rootElement);
	}
})();
