import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '../../../components/dropdown/dropdown';
import { LogoutButton } from '../../../components/logout-button/logout-button';
import { useInject } from '../../../recipes/use-inject';
import { useStore } from '../../../recipes/use-store';
import { authStoreMap } from '../../../stores/helpers/store-maps';
import { FilterModel } from '../../../stores/models/filter';
import { AutoRefresh } from '../../auto-refresh/auto-refresh';
import { DataGenerated } from '../../data-generated/data-generated';
import { TimeFilters } from '../../time-filters/time-filters';
import Timezone from '../../timezone/timezone';

export const LayoutHeader = () => {
	const { t } = useTranslation();
	const {
		uiStore: { viewStore },
		dataStore: { merchantStore, teamStore, filterStore }
	} = useStore();

	const { authStore } = useInject(authStoreMap);

	return useObserver(() => {
		const { allowAutoRefresh, selectedFilters, filters } = viewStore;
		const shouldShowMerchants = Boolean(viewStore.isPartnerUser && merchantStore.merchants.length);
		const shouldShowTeams = Boolean(viewStore.teams.length > 0);
		const shouldFilters = Boolean(filters.length);

		return (
			<div className="layout-header">
				{shouldShowMerchants && (
					<div className="merchant-dropdown header-dropdown">
						<Dropdown
							title={t('MERCHANTS')}
							placeholder={t('ALL_MERCHANTS')}
							items={merchantStore.allMerchants}
							value={viewStore.selectedMerchantId}
							disabled={viewStore.isDemoUser}
							onSelect={viewStore.onMerchantSelect}
						/>
					</div>
				)}
				{shouldShowTeams && (
					<div className="team-dropdown header-dropdown">
						<Dropdown
							title={t('TEAMS')}
							placeholder={t('ALL_TEAMS')}
							items={viewStore.teams}
							value={viewStore.selectedTeamId}
							disabled={viewStore.isDemoUser}
							loading={teamStore.isLoading}
							onSelect={viewStore.onTeamSelect}
						/>
					</div>
				)}
				{shouldFilters &&
					(filters as FilterModel[]).map(({ filter_type, values }) => (
						<div key={filter_type} className="filter-dropdown header-dropdown">
							<Dropdown
								title={t(filter_type)}
								placeholder={t(`ALL_${filter_type}`)}
								items={values}
								value={selectedFilters.has(filter_type) ? selectedFilters.get(filter_type) : null}
								disabled={viewStore.isDemoUser || filterStore.isLoading}
								loading={filterStore.isLoading}
								onSelect={(id: number | null) => {
									viewStore.updateSelectedFilter(filter_type, id);
								}}
							/>
						</div>
					))}
				<TimeFilters />
				<Timezone />
				<div className="data-generated-col">
					{allowAutoRefresh && <AutoRefresh />}

					<DataGenerated />
				</div>

				{!authStore.isUsedAsIframe && (
					<div className="logout-col">
						<LogoutButton />
					</div>
				)}
			</div>
		);
	});
};
