import { LAYOUT_SOURCE, LayoutPayloadOptions, ReportsLayout, ReportsWidget } from '@bringg/types';
import _compact from 'lodash/compact';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { flow, getEnv, getRoot, Instance, types } from 'mobx-state-tree';

import notifyError from '../../services/notify-error';
import { RootStoreEnv } from '../helpers/create-store';
import { getFilterTypes } from '../helpers/store-utils';
import { Layout } from '../models/layout';
import { Widget, WidgetModel } from '../models/widget/widget';
import { RootStoreModel } from '../root-store';

export type LayoutStoreModel = Instance<typeof LayoutStore>;
const LAYOUT_REQUEST: LayoutPayloadOptions = { source: LAYOUT_SOURCE.BI_DASHBOARD };

export const LayoutStore = types
	.model('LayoutStore', {
		layout: types.maybeNull(Layout)
	})
	.actions(self => ({
		createWidget: (bringgWidget: ReportsWidget): WidgetModel => {
			const widget = {
				id: bringgWidget.id,
				uuid: bringgWidget.uuid,
				position: bringgWidget.position,
				title: bringgWidget.widget.title,
				query_id: _get(bringgWidget.widget, 'query', bringgWidget.widget.queries),
				type: bringgWidget.widget.component_type,
				target_operator: bringgWidget.widget.target_operator,
				description: bringgWidget.widget.description,
				options: _get(bringgWidget, 'widget.options.kpi', {}),
				grouping_types: bringgWidget.widget.grouping_type,
				visibility_options: bringgWidget.widget.visibility_options,
				details_query: bringgWidget.widget.details_query
			};

			return Widget.create(widget);
		},
		setLayout: layout => {
			try {
				self.layout = Layout.create(layout);
			} catch (error) {
				console.log('failed to create layout with error', error);
			}
		}
	}))
	.actions(self => ({
		fetchLayout: flow(function* () {
			try {
				const { viewStore } = getRoot<RootStoreModel>(self).uiStore;
				let response;

				if (viewStore.isDemoUser) {
					const demoData = getEnv<RootStoreEnv>(self).demoData;
					response = demoData.getLayout();
				} else {
					const dashboardSdkService = getEnv<RootStoreEnv>(self).dashboardSdk;
					response = yield dashboardSdkService.sdk.reports.getLayout(LAYOUT_REQUEST);
				}

				const {
					id,
					merchant_id,
					reports_widgets,
					version,
					partner_id,
					filters
				} = response.layout as ReportsLayout;
				const reportsWidgets = _map(reports_widgets, (widget: ReportsWidget) => {
					try {
						return self.createWidget(widget);
					} catch (e) {
						console.log(`Failed to create widget uuid: ${widget.uuid}`, e);
					}
				});

				const { timeFilters, filterTypes } = getFilterTypes(filters);

				self.layout = Layout.create({
					id,
					merchant_id,
					version,
					partner_id,
					reports_widgets: _compact(reportsWidgets),
					time_filters: timeFilters,
					filter_types: filterTypes
				});
			} catch (e) {
				notifyError(getEnv<RootStoreEnv>(self), new Error('Failed to fetch layout'));
				console.log(`failed to fetch layout ${self}`, e);
			}
		})
	}));
