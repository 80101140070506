import {
	endOfDay,
	lastDayOfMonth,
	startOfDay,
	startOfMonth,
	startOfWeek,
	startOfYear,
	subDays,
	subMonths,
	subWeeks,
	subYears
} from 'date-fns';

import { Fn } from '../../types/functions.type';
import { getDateRange, getToday } from '../../util/date.util';
import { CompareToRange, DateRanges, FromToDateRange } from './time-filters.type';

export const getTranslatedBaseRanges = (t: Fn<string, string>): DateRanges => {
	return {
		[t('DATE_TODAY')]: { range: [startOfDay(getToday()), endOfDay(getToday())] },
		[t('DATE_YESTERDAY')]: { range: [subDays(getToday(), 1), subDays(endOfDay(getToday()), 1)] },
		[t('DATE_THIS_WEEK')]: { range: [startOfWeek(getToday()), endOfDay(getToday())] },
		[t('DATE_THIS_MONTH')]: { range: [startOfMonth(getToday()), endOfDay(getToday())] },
		[t('DATE_THIS_YEAR')]: { range: [startOfYear(getToday()), endOfDay(getToday())] }
	};
};

export const getTranslatedCompareRanges = (t: Fn<string, string>): Record<string, CompareToRange> => {
	return {
		[t('DATE_TODAY')]: {
			[t('DATE_YESTERDAY')]: {
				range: [subDays(getToday(), 1), subDays(endOfDay(getToday()), 1)],
				isDefault: false
			},
			[t('DATE_SAME_DAY_LAST_WEEK')]: {
				range: [subWeeks(getToday(), 1), subWeeks(endOfDay(getToday()), 1)],
				isDefault: true
			}
		},
		[t('DATE_YESTERDAY')]: {
			[t('DATE_TWO_DAYS_AGO')]: {
				range: [subDays(getToday(), 2), subDays(endOfDay(getToday()), 2)],
				isDefault: false
			},
			[t('DATE_SAME_DAY_LAST_WEEK')]: {
				range: [subWeeks(subDays(getToday(), 1), 1), subWeeks(subDays(endOfDay(getToday()), 1), 1)],
				isDefault: true
			}
		},
		[t('DATE_THIS_WEEK')]: {
			[t('DATE_SAME_DAYS_LAST_WEEK')]: {
				range: [subWeeks(startOfWeek(getToday()), 1), subWeeks(getToday(), 1)],
				isDefault: true
			}
		},
		[t('DATE_THIS_MONTH')]: {
			[t('DATE_PREVIOUS_MONTH')]: {
				range: [subMonths(startOfMonth(getToday()), 1), lastDayOfMonth(subMonths(getToday(), 1))],
				isDefault: false
			},
			[t('DATE_SAME_TIME_LAST_YEAR')]: {
				range: [subYears(startOfMonth(getToday()), 1), subYears(endOfDay(getToday()), 1)],
				isDefault: true
			}
		},
		[t('DATE_THIS_YEAR')]: {
			[t('DATE_SAME_TIME_LAST_YEAR')]: {
				range: [subYears(startOfYear(getToday()), 1), subYears(getToday(), 1)],
				isDefault: true
			}
		}
	};
};

export const updateTimeFiltersInStore = (selectedDates: FromToDateRange, action: (start: Date, end: Date) => void) => {
	const { startDate, endDate } = getDateRange(selectedDates);
	action(startDate, endDate);
};

export const setDefaultCompareToRange = (compareToRange: CompareToRange, action: (start: Date, end: Date) => void) => {
	Object.values(compareToRange).forEach(({ range, isDefault }) => {
		if (isDefault) {
			updateTimeFiltersInStore(range, action);
		}
	});
};

export const getRangePickerClassName = (title: string) => {
	const titleClasses = title
		.split(' ')
		.map(s => s.toLowerCase())
		.join('-');

	return `${titleClasses}-rangepicker`;
};
