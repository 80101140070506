import { MetricType } from '@bringg/react-components/dist/components/metric-counter/metric-counter';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { GroupingDataModel } from '../../../stores/models/grouping-data';
import SegmentBar from './segment-bar';

interface Props {
	data: GroupingDataModel;
	metricType: MetricType;
}

const NonTimeStackedSegmentBar = ({ data, metricType }: Props) => {
	return useObserver(() => <SegmentBar data={data} metricType={metricType} stacked={true} isDatetime={false} />);
};

export default NonTimeStackedSegmentBar;
