import _forEach from 'lodash/forEach';
import { flow, getEnv, getRoot, Instance, types } from 'mobx-state-tree';

import { RootStoreEnv } from '../helpers/create-store';
import { Team } from '../models/team';
import { RootStoreModel } from '../root-store';

export interface TeamStoreModel extends Instance<typeof TeamStore> {}

export const TeamStore = types
	.model('TeamStore', {
		teams: types.array(Team),
		isLoading: false
	})
	.actions(self => {
		const handleFetchedTeams = (teams: Bringg.Team[]): void => {
			self.teams.clear();
			getRoot<RootStoreModel>(self).uiStore.viewStore.updateSelectedTeam(null);

			_forEach(teams, ({ id, name, merchant_id }) => self.teams.push(Team.create({ id, name, merchant_id })));
		};

		const fetchByMerchant = flow(function* (merchantId: number) {
			self.isLoading = true;
			const dashboardSdkService = getEnv<RootStoreEnv>(self).dashboardSdk;
			const teams = yield dashboardSdkService.sdk.teams.byMerchantId(merchantId);

			handleFetchedTeams(teams);
			self.isLoading = false;
		});

		const fetchAll = flow(function* () {
			const dashboardSdkService = getEnv<RootStoreEnv>(self).dashboardSdk;
			const teams = yield dashboardSdkService.sdk.teams.getAll();

			handleFetchedTeams(teams);
		});

		return {
			fetchAll,
			fetchByMerchant
		};
	})
	.actions(self => ({
		setTeams(teams) {
			self.teams = teams;
		}
	}));
