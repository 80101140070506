import { MetricType } from '@bringg/react-components/dist/components/metric-counter/metric-counter';
import moment from 'moment';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

import { TIME_FILTER_OPTION } from '../../../features/time-filters/time-filters.consts';
import { convertValue, getDefaultWidgetOptions } from '../../../features/widgets/widgets.config';
import { useStore } from '../../../recipes/use-store';
import { GroupingDataModel } from '../../../stores/models/grouping-data';
import { getSeries } from '../../widget-template/widget-utils';

interface Props {
	data: GroupingDataModel;
	metricType: MetricType;
}

export const getOptions = (groupingData: GroupingDataModel, metricType: MetricType): any => {
	const defaultOptions = getDefaultWidgetOptions(metricType);

	return {
		...defaultOptions,
		xaxis: {
			...defaultOptions.xaxis,
			categories: groupingData.keys.map(v => parseInt(v)).sort(),
			type: 'datetime'
		},
		tooltip: {
			enabled: true,
			y: {
				formatter: (val, { dataPointIndex }) => {
					const hoveredMilliseconds = parseInt(groupingData.keys[dataPointIndex]);
					const formattedDate = moment(hoveredMilliseconds).local().format('MM/DD/YYYY');

					return `<div>${convertValue(val, metricType)}</div>&nbsp; <div>(${formattedDate})</div>`;
				},
				title: {
					formatter: seriesName => seriesName.split('(')[0]
				}
			}
		},
		chart: {
			...defaultOptions.chart,
			id: `line_chart_${groupingData.grouping}`
		}
	};
};

const LineChart = ({ data, metricType }: Props) => {
	const { uiStore } = useStore();
	const trimData = ([TIME_FILTER_OPTION.TODAY, TIME_FILTER_OPTION.YESTERDAY] as string[]).includes(
		uiStore.viewStore.selectedTimeFilter?.type
	);
	const chartOptions = getOptions(data, metricType);
	const chartSeries = getSeries(data, trimData);

	return (
		<div className="chart-item line-chart">
			<ReactApexChart type="line" options={chartOptions} series={chartSeries} />
		</div>
	);
};

export default LineChart;
