import { types } from 'mobx-state-tree';

import { FilterStore, FilterStoreModel } from './filter-store';
import { LayoutStore, LayoutStoreModel } from './layout-store';
import { MerchantStore, MerchantStoreModel } from './merchant-store';
import { TeamStore, TeamStoreModel } from './team-store';

export interface DataStoreModel {
	layoutStore: LayoutStoreModel;
	merchantStore: MerchantStoreModel;
	teamStore: TeamStoreModel;
	filterStore: FilterStoreModel;
}

export const DataStore = types
	.model('DataStore', {
		layoutStore: types.maybeNull(LayoutStore),
		merchantStore: types.maybeNull(MerchantStore),
		teamStore: types.maybeNull(TeamStore),
		filterStore: types.maybeNull(FilterStore)
	})
	.actions(self => ({
		afterCreate() {
			self.layoutStore = LayoutStore.create();
			self.merchantStore = MerchantStore.create();
			self.teamStore = TeamStore.create();
			self.filterStore = FilterStore.create();
		}
	}));
