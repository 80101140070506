import _filter from 'lodash/filter';
import _get from 'lodash/get';
import { flow, getEnv, getRoot, Instance, types } from 'mobx-state-tree';

import notifyError from '../../services/notify-error';
import { RootStoreEnv } from '../helpers/create-store';
import { RootStoreModel } from '../root-store';
import { Widget, WidgetModel } from './widget/widget';

export type LayoutModel = Instance<typeof Layout>;

export const Layout = types
	.model('Layout', {
		id: types.union(types.string, types.number),
		merchant_id: types.maybeNull(types.union(types.number, types.null)),
		version: types.maybeNull(types.number),
		partner_id: types.maybeNull(types.number),
		reports_widgets: types.array(Widget),
		time_filters: types.array(types.string),
		filter_types: types.array(types.string)
	})
	.views(self => ({
		get visibleWidgets(): WidgetModel[] {
			const { viewStore } = getRoot<RootStoreModel>(self).uiStore;
			return _filter(self.reports_widgets, widget => {
				return !(viewStore.isPartnerLevel && _get(widget, 'visibility_options.hide_for_partner'));
			});
		}
	}))
	.actions(self => {
		const setLastUpdateTime = flow(function* () {
			const viewStore = getRoot<RootStoreModel>(self).uiStore.viewStore;
			const dashboardSdk = getEnv<RootStoreEnv>(self).dashboardSdk.sdk;

			try {
				const lastUpdateTime = yield dashboardSdk.reports.getLastUpdateTime();
				viewStore.updateLastTimestamp(lastUpdateTime);
			} catch (e) {
				notifyError(getEnv<RootStoreEnv>(self), new Error('Failed get last updated time'));
				console.log('could not get last update time', e);
			}
		});

		return {
			setLastUpdateTime,
			refreshData() {
				setLastUpdateTime();

				self.visibleWidgets.forEach(widget => {
					widget.setLoading(true);
				});
			},

			stopPolling() {
				self.reports_widgets.forEach(widget => widget.stopPolling());
			},
			startPolling() {
				self.reports_widgets.forEach(widget => widget.startPolling());
			}
		};
	});
