import { useEffect } from 'react';

const useOutsideClick = (className, cb) => {
	useEffect(() => {
		const handleClickOutside = event => {
			const domElem = document.getElementsByClassName(className)[0];

			if (domElem && !domElem.contains(event.target)) {
				cb();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [className, cb]);
};

export default useOutsideClick;
