import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const PERCENTAGE_SIGN: string = '%';

interface Props {
	trend: number;
	positiveTarget: boolean;
}

const getPercentage = (trend: number): string => {
	const prefix = trend > 0 ? '+' : '';
	const value = Math.round(trend * 100);

	return `${prefix}${value}${PERCENTAGE_SIGN}`;
};

const getClassName = (trend: number, positive: boolean) => {
	if (!trend) {
		return 'neutral';
	}

	if (positive) {
		return trend > 0 ? 'positive' : 'negative';
	}

	return trend > 0 ? 'negative' : 'positive';
};

const TargetPercentage = ({ trend, positiveTarget }: Props) => {
	return (
		<div className="target-percentage">
			<div className={getClassName(trend, positiveTarget)}>
				{trend ? getPercentage(trend) : 'NA'}
				{trend ? (
					<FontAwesomeIcon icon={trend > 0 ? faSortUp : faSortDown} className="sign-icon" size="sm" />
				) : (
					''
				)}
			</div>
		</div>
	);
};

export default TargetPercentage;
