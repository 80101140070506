import _first from 'lodash/first';
import _includes from 'lodash/includes';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';

import { GroupingDataModel } from '../../stores/models/grouping-data';
import { SeriesItem } from '../widget-types/segment-bar/segment-bar';

export const getSeries = (groupingData: GroupingDataModel, trimData = false): SeriesItem[] => {
	const prefixToRemove = getCommonPrefix(groupingData);
	const values = trimData ? calculateTrimmedData(groupingData.valuesByName) : groupingData.valuesByName;

	return _sortBy(
		_map(values, (data, name) => {
			if (prefixToRemove) {
				name = name.replace(prefixToRemove, '');
			}

			if (name && name.length > 10) {
				name = name.split(' ')[0];
			}
			return { name, data } as SeriesItem;
		}),
		'name'
	);
};

export const getCommonPrefix = (groupingData: GroupingDataModel): string | null => {
	const names = Object.keys(groupingData.valuesByName || []);

	if (_includes(_first(names), ' ')) {
		const commonPrefixToSearch = names[0].split(' ')[0];

		if (names.every(name => name.includes(commonPrefixToSearch))) {
			return commonPrefixToSearch + ' ';
		}
	}

	return null;
};

const trimArray = <T extends number>(arr: T[]): T[] => {
	let isLeftBorderFound = false;
	let leftBorder = 0;
	let rightBorder = 0;

	for (let i = 0; i < arr.length; i++) {
		if (arr[i] === 0 && !isLeftBorderFound) {
			leftBorder = i;
			isLeftBorderFound = arr[i + 1] !== 0;
		}

		if (arr[i] !== 0) {
			rightBorder = i;
		}
	}

	leftBorder = arr[0] !== 0 ? 0 : leftBorder + 1;
	rightBorder = rightBorder === 0 ? undefined : rightBorder + 1;

	return arr.slice(leftBorder, rightBorder);
};

const calculateTrimmedData = <T extends number>(obj: Record<string, T | T[]>): Record<string, T | T[]> => {
	const newObj = {};

	Object.entries(obj).forEach(([key, value]) => {
		newObj[key] = Array.isArray(value) ? trimArray(value) : value;
	});

	return newObj;
};
