import { AirbrakeService } from '@bringg/frontend-utils';

import { RootStoreEnv } from '../stores/helpers/create-store';

const notifyError = (env: RootStoreEnv, error: any, extraData?: any) => {
	const currentUser = env.dashboardSdk?.sdk.session.user;
	const airbrake = env.airbrakeService as AirbrakeService;

	airbrake?.notify({
		error,
		params: {
			...extraData,
			userId: currentUser.id,
			merchantId: currentUser.merchant_id,
			version: process.env.REACT_APP_VERSION,
			admin: currentUser.admin,
			dispatcher: currentUser.dispatcher,
			partner: currentUser.partner_user,
			region: currentUser.region
		},
		context: {
			severity: 'error',
			usedAsIframe: env.usedAsIframe,
			version: process.env.REACT_APP_VERSION,
			environment: process.env.NODE_ENV,
			region: currentUser.region
		}
	});
};

export default notifyError;
