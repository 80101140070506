import { Login as LoginComponent } from '@bringg/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useInject } from '../../recipes/use-inject';
import notification from '../../services/notification';
import { authStoreMap } from '../../stores/helpers/store-maps';

const MIN_PASSWORD_LENGTH: number = 4;

const Login = () => {
	const { authStore } = useInject(authStoreMap);
	const { t } = useTranslation();

	const onLoginFailed = () => {
		notification.error(t('LOGIN_LOGIN_FAILED'), t('LOGIN_LOGIN_FAILED_DESCRIPTION'));
	};

	return (
		<div className="login">
			<LoginComponent
				minPasswordLength={MIN_PASSWORD_LENGTH}
				emailPlaceholder={t('LOGIN_EMAIL')}
				invalidEmailText={t('LOGIN_EMAIL_NOT_VALID')}
				invalidPasswordText={t('LOGIN_PASSWORD_NOT_VALID', {
					min_characters: MIN_PASSWORD_LENGTH
				})}
				recaptchaSiteKey={authStore.recaptchaSiteKey}
				loginTitle={t('LOGIN_WELCOME')}
				passwordPlaceholder={t('LOGIN_PASSWORD')}
				signInText={t('LOGIN_SIGN_IN')}
				onLoggedIn={() => authStore.setLogin(true)}
				onLogin={authStore.attemptToLogin}
				onLoginFailed={onLoginFailed}
			/>
		</div>
	);
};

export default Login;
