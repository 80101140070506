import { useObserver } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { useStore } from '../../recipes/use-store';
import { LayoutBody } from './layout-body/layout-body';
import { LayoutHeader } from './layout-header/layout-header';

export const Layout = () => {
	const {
		dataStore: { layoutStore, teamStore, merchantStore, filterStore },
		uiStore: { viewStore }
	} = useStore();

	useEffect(() => {
		async function asyncEffect() {
			await layoutStore.fetchLayout();
			merchantStore.getConfiguration();
			if (viewStore.isPartnerUser) {
				merchantStore.fetchAll();
			} else {
				teamStore.fetchAll();
				filterStore.fetch();
			}
		}

		asyncEffect();
	}, [layoutStore, viewStore.isPartnerUser, teamStore, merchantStore, filterStore]);

	return useObserver(() => (
		<div className="app-layout">
			<LayoutHeader />
			<LayoutBody />
		</div>
	));
};

export default Layout;
