import { Tooltip } from '@bringg/react-components';
import MetricCounter, { MetricType } from '@bringg/react-components/dist/components/metric-counter/metric-counter';
import { COMPONENT_TYPES, TARGET_OPERATORS } from '@bringg/types';
import _find from 'lodash/find';
import _isNil from 'lodash/isNil';
import { useLocalStore, useObserver } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { GroupingDataModel } from '../../stores/models/grouping-data';
import { WidgetModel } from '../../stores/models/widget/widget';
import GroupingSelect from '../grouping-select/grouping-select';
import TargetPercentage from '../target-percentage/target-percentage';

// DT-234
const AVG_RATING_QUERY = 35;

const getAvgRating = (values: Record<string, number | number[]>, total: number) =>
	Object.entries(values).reduce((acc, [mark, markValue]) => acc + parseInt(mark) * (markValue as number), 0) / total;

const getCurrentGroupingData = (groupingData: GroupingDataModel[], selectedGrouping: string) =>
	_find(groupingData, data => data.grouping === selectedGrouping);

interface Props {
	widget: WidgetModel;
	WidgetComponent: (props: { data: GroupingDataModel; metricType: MetricType }) => any;
	metricType: MetricType;
	currencySign?: string;
}

const WidgetTemplate = ({ widget, WidgetComponent, metricType, currencySign }: Props) => {
	const { title, trend, target_operator, total, grouping_types, grouping_data } = widget;
	const { t } = useTranslation();
	const groupingLocalStore = useLocalStore(() => ({
		selected: grouping_types[0],
		setSelectedGrouping(grouping: string) {
			groupingLocalStore.selected = grouping;
		}
	}));

	return useObserver(() => {
		const currentGroupingData: GroupingDataModel = getCurrentGroupingData(
			grouping_data,
			groupingLocalStore.selected
		);

		//exception as per https://bringg.atlassian.net/browse/BRNGG-1447
		const isRatingPie = widget.type === COMPONENT_TYPES.PIE && widget.query_id === AVG_RATING_QUERY;
		const metricValue = (isRatingPie
			? getAvgRating(currentGroupingData.valuesByName, total as number)
			: total) as number;

		return (
			<div className="widget-template">
				<Tooltip key={`${widget.title}-tooltip`} title={widget.description} placement="top">
					<div className="title">
						{t(title)}
						<MetricCounter
							className="total-value"
							metricType={metricType}
							metricValue={metricValue}
							currencySign={currencySign}
						/>
					</div>
					{!_isNil(trend) && (
						<TargetPercentage trend={trend} positiveTarget={target_operator === TARGET_OPERATORS.BIGGER} />
					)}
					{currentGroupingData && <WidgetComponent data={currentGroupingData} metricType={metricType} />}
					{grouping_types.length > 1 && (
						<GroupingSelect
							groupingOptions={grouping_types}
							selectedGrouping={groupingLocalStore.selected}
							onSelect={groupingLocalStore.setSelectedGrouping}
						/>
					)}
				</Tooltip>
			</div>
		);
	});
};

export default WidgetTemplate;
