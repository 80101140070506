import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	groupingOptions: string[];
	onSelect: (groupingOption: string) => void;
	selectedGrouping: string;
}

const GroupingSelect = ({ groupingOptions, onSelect, selectedGrouping }: Props) => {
	const { t } = useTranslation();
	const colSpan = Math.floor(24 / groupingOptions.length);

	return (
		<Row className="grouping-select" justify="space-around">
			{groupingOptions.map(groupingOption => (
				<Col
					key={groupingOption}
					span={colSpan}
					className={`grouping-option ${
						groupingOption === selectedGrouping ? 'selected-grouping-option' : ''
					}`}
					onClick={() => onSelect(groupingOption)}
				>
					{t(groupingOption)}
				</Col>
			))}
		</Row>
	);
};

export default GroupingSelect;
