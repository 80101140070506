import { RootStoreModel } from '../stores/root-store';
import { useStore } from './use-store';

type MapStoreFn<T> = (store: RootStoreModel) => T;

export const useInject = <T>(mapStoreFn: MapStoreFn<T>) => {
	const store = useStore();

	return mapStoreFn(store);
};
