import { WIDGET_GROUPING_TYPES } from '@bringg/types';
import _each from 'lodash/each';
import _map from 'lodash/map';
import moment from 'moment';

import { TIME_FILTER_OPTION } from '../features/time-filters/time-filters.consts';
import { TimeModel } from '../stores/models/time';
import { layout } from './demo-data/layout';
import { TotalReportsByUuidAndTime } from './demo-data/report_by_uuid';

const ONE_HOUR = 60 * 60 * 1000;
const ONE_DAY = 24 * ONE_HOUR;
const ONE_WEEK = 7 * ONE_DAY;
const ONE_MONTH = 30 * ONE_DAY;
const ONE_YEAR = 365 * ONE_DAY;

export class DemoData {
	public getLayout() {
		return { layout };
	}

	public getReport(uuid: string, timeFilter: TimeModel, groupingType: WIDGET_GROUPING_TYPES) {
		let time = TIME_FILTER_OPTION.LAST_24H;
		const diff = moment(timeFilter.to).diff(timeFilter.from);
		let requestUuid = uuid;

		if (diff >= ONE_YEAR) {
			time = TIME_FILTER_OPTION.LAST_YEAR;
		} else if (diff >= ONE_MONTH) {
			time = TIME_FILTER_OPTION.LAST_MONTH;
		} else if (diff >= ONE_WEEK) {
			time = TIME_FILTER_OPTION.LAST_7D;
		}

		if (Object.values(WIDGET_GROUPING_TYPES).includes(groupingType)) {
			requestUuid += '-' + groupingType;
		}

		const report = TotalReportsByUuidAndTime.get(requestUuid).get(time);
		this.fixKeys(report, time, timeFilter.from);
		return report;
	}

	private fixKeys(report, time: TIME_FILTER_OPTION, startTime: number) {
		let currentKey;

		if (report.values) {
			let incrementBy = ONE_DAY;

			if (time === TIME_FILTER_OPTION.LAST_YEAR) {
				incrementBy = ONE_WEEK;
			} else if (time === TIME_FILTER_OPTION.LAST_24H) {
				incrementBy = ONE_HOUR;
			}

			report.values = _map(report.values, object => {
				currentKey = startTime;
				const fixedValues = {};
				_each(object.values, value => {
					fixedValues[currentKey] = value;
					currentKey += incrementBy;
				});
				return {
					...object,
					values: fixedValues
				};
			});
		}
	}
}
