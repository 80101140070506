import { FILTER_TYPES } from '@bringg/types';
import { reaction } from 'mobx';
import { addDisposer, getEnv, getRoot } from 'mobx-state-tree';

import { TIME_FILTER_OPTION, TimeFilterInterface } from '../../../features/time-filters/time-filters.consts';
import notifyError from '../../../services/notify-error';
import { RootStoreEnv } from '../../helpers/create-store';
import { Time } from '../../models/time';
import { RootStoreModel } from '../../root-store';

const viewStoreBaseActions = self => {
	const updateAutoRefresh = (autoRefresh: boolean) => {
		self.autoRefresh = autoRefresh;
	};
	const updateAllowAutoRefresh = (allowAutoRefresh: boolean) => {
		self.allowAutoRefresh = allowAutoRefresh;
	};
	const updateSelectedTeam = (teamId: number) => {
		self.selectedTeamId = teamId;
	};
	const updateSelectedMerchant = (merchantId: number) => {
		self.selectedTeamId = null;
		self.selectedMerchantId = merchantId;
	};

	const updateSelectedTimeFilter = (timeFilter: TimeFilterInterface) => {
		const { from, to } = timeFilter.getValue();

		if (timeFilter.key === TIME_FILTER_OPTION.TODAY) {
			self.updateAllowAutoRefresh(true);
		} else {
			self.updateAllowAutoRefresh(false);
			self.updateAutoRefresh(false);
		}

		if (!self.selectedTimeFilter) {
			self.selectedTimeFilter = Time.create({ from, to, type: TIME_FILTER_OPTION.TODAY });
		} else {
			self.selectedTimeFilter.updateTimes(from, to, timeFilter.key);
		}
	};

	const updateSelectTimeFilterByValues = (from: Date, to: Date) => {
		if (!self.selectedTimeFilter) {
			self.selectedTimeFilter = Time.create({ from: from.valueOf(), to: to.valueOf() });
		} else {
			self.selectedTimeFilter.updateTimes(from.valueOf(), to.valueOf());
		}

		self.updateAllowAutoRefresh(false);
		self.updateAutoRefresh(false);
	};

	const updateSelectCompareTimeFilterByValues = (from: Date, to: Date) => {
		if (!self.selectedCompareTimeFilter) {
			self.selectedCompareTimeFilter = Time.create({ from: from.valueOf(), to: to.valueOf() });
		} else {
			self.selectedCompareTimeFilter.updateTimes(from.valueOf(), to.valueOf());
		}

		self.updateAllowAutoRefresh(false);
		self.updateAutoRefresh(false);
	};

	const updatePartnerUserFlag = (isPartnerUser: boolean) => {
		self.isPartnerUser = isPartnerUser;
	};

	const updateLastTimestamp = (timestamp: number) => {
		self.lastUpdateTimestamp = timestamp;
	};

	return {
		updateAllowAutoRefresh,
		updateAutoRefresh,
		updatePartnerUserFlag,
		updateSelectedTeam,
		updateSelectedTimeFilter,
		updateSelectTimeFilterByValues,
		updateSelectCompareTimeFilterByValues,
		updateLastTimestamp,
		updateSelectedMerchant
	};
};

const viewStoreActions = self => {
	const afterAttach = () => {
		const reactionDisposer = reaction(
			() => getRoot<RootStoreModel>(self).uiStore.authStore.isLoggedIn,
			isLoggedIn => {
				if (isLoggedIn) {
					const dashboardSdk = getEnv<RootStoreEnv>(self).dashboardSdk.sdk;
					const user = dashboardSdk.session.user;

					self.updatePartnerUserFlag(user.partner_user);
				}
			}
		);

		let teamDisposer,
			merchantDisposer,
			timeFilterDisposer,
			autoRefreshDisposer,
			filtersDisposer,
			compareToFilterDisposer;

		const loadData = () => {
			getRoot<RootStoreModel>(self).dataStore.layoutStore.layout.refreshData();
		};

		teamDisposer = reaction(
			() => self.selectedTeamId,
			() => loadData()
		);

		merchantDisposer = reaction(
			() => self.selectedMerchantId,
			() => loadData()
		);

		filtersDisposer = reaction(
			() => self.getSelectedFilters,
			() => loadData()
		);

		timeFilterDisposer = reaction(() => {
			const timeFilter = self.selectedTimeFilter;

			return timeFilter ? `${timeFilter.from.toString()}-${timeFilter.to.toString()}` : null;
		}, loadData);

		compareToFilterDisposer = reaction(() => {
			const compareToFilter = self.selectedCompareTimeFilter;

			return compareToFilter ? `${compareToFilter.from.toString()}-${compareToFilter.to.toString()}` : null;
		}, loadData);

		autoRefreshDisposer = reaction(
			() => self.autoRefresh,
			newValue => {
				const { layoutStore } = getRoot<RootStoreModel>(self).dataStore;

				if (self.autoRefresh) {
					layoutStore.layout.startPolling();
				} else {
					layoutStore.layout.stopPolling();
				}
			}
		);

		addDisposer(self, teamDisposer);
		addDisposer(self, merchantDisposer);
		addDisposer(self, filtersDisposer);
		addDisposer(self, timeFilterDisposer);
		addDisposer(self, compareToFilterDisposer);
		addDisposer(self, reactionDisposer);
		addDisposer(self, autoRefreshDisposer);
	};

	const onMerchantSelect = (merchantId?: number) => {
		if (!merchantId) {
			self.updateSelectedMerchant(merchantId);
			self.clearFilters();
			return;
		}

		const { teamStore, filterStore } = getRoot<RootStoreModel>(self).dataStore;

		try {
			self.updateSelectedMerchant(merchantId);
			teamStore.fetchByMerchant(merchantId);
			filterStore.fetch();
		} catch (e) {
			notifyError(getEnv<RootStoreEnv>(self), new Error('Failed to select merchant'), {
				error: JSON.stringify(e),
				selectedTeamId: self.selectedTeamId,
				selectedMerchantId: self.selectedMerchantId
			});
			console.log('Failed to select merchant', e);
		}
	};

	const onTeamSelect = async (teamId: number) => {
		self.updateSelectedTeam(teamId);

		const { filterStore } = getRoot<RootStoreModel>(self).dataStore;
		await filterStore.fetch();
	};

	const updateSelectedFilter = (filterType: FILTER_TYPES, id: number | null) => {
		if (id === null) {
			self.selectedFilters.delete(filterType);
		} else {
			self.selectedFilters.set(filterType, id);
		}
	};

	const clearFilters = () => {
		self.selectedFilters.clear();
	};

	return {
		afterAttach,
		onMerchantSelect,
		onTeamSelect,
		updateSelectedFilter,
		clearFilters
	};
};

export { viewStoreActions, viewStoreBaseActions };
