import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DetailsQueryModel } from '../../stores/models/widget/widget';

interface Props {
	detailsQuery: DetailsQueryModel;
}

const WidgetDetails = ({ detailsQuery: { error_text, error_values, warning_text, warning_values } }: Props) => {
	const { t } = useTranslation();

	return (
		<div className="widget-details">
			{!_isEmpty(error_values) && <div className="error_text">{t(error_text, { fleets: error_values })}</div>}
			{!_isEmpty(warning_values) && (
				<div className="warning_text">{t(warning_text, { fleets: warning_values })}</div>
			)}
		</div>
	);
};

export default WidgetDetails;
