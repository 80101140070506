import _isNil from 'lodash/isNil';
import { getEnv, getRoot, Instance, types } from 'mobx-state-tree';

import { RootStoreEnv } from '../../helpers/create-store';
import { Time } from '../../models/time';
import { RootStoreModel } from '../../root-store';
import { viewStoreActions, viewStoreBaseActions } from './view-store.actions';

export interface ViewStoreModel extends Instance<typeof ViewStore> {}
const BI_DASHBOARD_DEMO_USER_FF = 'bi_dashboard_demo';

export const ViewStore = types
	.model('ViewStore', {
		selectedFilters: types.map(types.number),
		selectedTeamId: types.maybeNull(types.number),
		selectedMerchantId: types.maybeNull(types.number),
		selectedTimeFilter: types.maybeNull(Time),
		selectedCompareTimeFilter: types.maybeNull(Time),
		lastUpdateTimestamp: types.optional(types.number, 0),
		isPartnerUser: false,
		autoRefresh: false,
		allowAutoRefresh: true
	})
	.actions(viewStoreBaseActions)
	.actions(viewStoreActions)
	.views(self => ({
		get teams() {
			const teams = getRoot<RootStoreModel>(self).dataStore.teamStore.teams;

			if (self.isPartnerUser) {
				return teams.filter(team => team.merchant_id === self.selectedMerchantId);
			}

			return teams;
		},
		get filters() {
			if (this.isPartnerLevel) {
				return [];
			}

			return getRoot<RootStoreModel>(self).dataStore.filterStore.filters;
		},
		get isDemoUser(): boolean {
			return getEnv<RootStoreEnv>(self).dashboardSdk.sdk.session.user.feature_flags[BI_DASHBOARD_DEMO_USER_FF];
		},
		get isPartnerLevel(): boolean {
			return self.isPartnerUser && _isNil(self.selectedMerchantId);
		},

		// computed value mainly for widget-wrapper useEffect
		get getSelectedFilters() {
			return Array.from(self.selectedFilters.values());
		}
	}));
