import { Space } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PickerRanges } from '../../components/picker-ranges/picker-ranges';
import { useStore } from '../../recipes/use-store';
import { DateFilterTimePicker } from './customer-time-filter/date-filter-time-picker';
import { CompareToRange, FromToDateRange } from './time-filters.type';
import {
	getTranslatedBaseRanges,
	getTranslatedCompareRanges,
	setDefaultCompareToRange,
	updateTimeFiltersInStore
} from './time-filters.utils';

export const TimeFilters = observer(() => {
	const {
		uiStore: { viewStore }
	} = useStore();
	const { t } = useTranslation();
	const [compareRanges, setCompareRanges] = useState<CompareToRange>({});
	const [isPeriodCalendarOpen, setPeriodCalendarOpen] = useState(false);
	const [isCompareCalendarOpen, setCompareCalendarOpen] = useState(false);

	const openPeriodCalendar = useCallback(() => setPeriodCalendarOpen(true), []);
	const openCompareCalendar = useCallback(() => setCompareCalendarOpen(true), []);
	const closePeriodCalendar = () => setPeriodCalendarOpen(false);
	const closeCompareCalendar = () => setCompareCalendarOpen(false);

	const baseRanges = useMemo(() => getTranslatedBaseRanges(t), [t]);
	const compareToRanges = useMemo(() => getTranslatedCompareRanges(t), [t]);

	useEffect(() => {
		const necessaryCompareToRanges = compareToRanges[t('DATE_TODAY')];

		updateTimeFiltersInStore(baseRanges[t('DATE_TODAY')].range, viewStore.updateSelectTimeFilterByValues);
		setCompareRanges(necessaryCompareToRanges);
		setDefaultCompareToRange(necessaryCompareToRanges, viewStore.updateSelectCompareTimeFilterByValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [compareToRanges]);

	const onPeriodTimeRangeClick = (range: FromToDateRange, rangeKey: string) => {
		const necessaryCompareToRanges = compareToRanges[rangeKey];

		updateTimeFiltersInStore(range, viewStore.updateSelectTimeFilterByValues);
		setCompareRanges(necessaryCompareToRanges);
		setDefaultCompareToRange(necessaryCompareToRanges, viewStore.updateSelectCompareTimeFilterByValues);
		setPeriodCalendarOpen(false);
	};

	const onCompareToRangeClick = selectedDates => {
		updateTimeFiltersInStore(selectedDates, viewStore.updateSelectCompareTimeFilterByValues);
		setCompareCalendarOpen(false);
	};

	const clearCompareToRanges = () => setCompareRanges({});

	const compareTimeValue: FromToDateRange | undefined = viewStore.selectedCompareTimeFilter?.from &&
		viewStore.selectedCompareTimeFilter?.to && [
			new Date(viewStore.selectedCompareTimeFilter.from),
			new Date(viewStore.selectedCompareTimeFilter.to)
		];

	const timePeriodValue: FromToDateRange | undefined = viewStore.selectedTimeFilter?.from &&
		viewStore.selectedTimeFilter?.to && [
			new Date(viewStore.selectedTimeFilter.from),
			new Date(viewStore.selectedTimeFilter.to)
		];

	return (
		<Space>
			<DateFilterTimePicker
				title="Time Period"
				footer={() => <PickerRanges ranges={baseRanges} onRangeClick={onPeriodTimeRangeClick} />}
				value={timePeriodValue}
				isOpen={isPeriodCalendarOpen}
				updateRangeAction={viewStore.updateSelectTimeFilterByValues}
				onPickerInputClick={openPeriodCalendar}
				onCalendarRangeSet={closePeriodCalendar}
				onOutsideClick={closePeriodCalendar}
				onSelect={clearCompareToRanges}
			/>

			<DateFilterTimePicker
				title="Compare to"
				footer={() => <PickerRanges ranges={compareRanges} onRangeClick={onCompareToRangeClick} />}
				value={compareTimeValue}
				isOpen={isCompareCalendarOpen}
				updateRangeAction={viewStore.updateSelectCompareTimeFilterByValues}
				onPickerInputClick={openCompareCalendar}
				onCalendarRangeSet={closeCompareCalendar}
				onOutsideClick={closeCompareCalendar}
			/>
		</Space>
	);
});
