import { Merchant as MerchantType } from '@bringg/types';
import _first from 'lodash/first';
import _forEach from 'lodash/forEach';
import { toJS } from 'mobx';
import { flow, getEnv, getRoot, Instance, types } from 'mobx-state-tree';

import { RootStoreEnv } from '../helpers/create-store';
import { Merchant } from '../models/merchant';
import { MerchantConfiguration } from '../models/merchantConfiguration';
import { RootStoreModel } from '../root-store';

export type MerchantStoreModel = Instance<typeof MerchantStore>;

export const MerchantStore = types
	.model('MerchantStore', {
		merchants: types.optional(types.array(Merchant), []),
		merchantConfiguration: types.maybeNull(MerchantConfiguration)
	})
	.actions(self => ({
		getConfiguration: flow(function* () {
			try {
				const configuration = yield getEnv<RootStoreEnv>(self).dashboardSdk.sdk.merchantConfiguration.get();

				self.merchantConfiguration = MerchantConfiguration.create(configuration);
			} catch (e) {
				console.log(`failed to get merchant configuration ${self}`, e);
			}
		}),
		fetchAll: flow(function* () {
			const dashboardSdkService = getEnv<RootStoreEnv>(self).dashboardSdk;
			const merchants: MerchantType[] = yield dashboardSdkService.sdk.merchant.getAll();

			function exist(id): boolean {
				let result = self.merchants.filter(item => item.id === id);
				return result.length > 0;
			}

			_forEach(merchants, ({ id, name, env_type }) => {
				if (env_type === 0 && !exist(id)) {
					self.merchants.push(Merchant.create({ id, name }));
				}
			});

			const { uiStore, dataStore } = getRoot<RootStoreModel>(self);
			const firstMerchant = _first(self.merchants);

			if (self.merchants.length === 1) {
				uiStore.viewStore.updateSelectedMerchant(firstMerchant.id as number);
			}

			dataStore.teamStore.fetchByMerchant(Number(firstMerchant.id)).then().catch();

			dataStore.filterStore.fetch();
		})
	}))
	.views(self => ({
		get allMerchants() {
			return toJS(self.merchants);
		}
	}));
