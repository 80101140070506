import { types } from 'mobx-state-tree';

import { AuthStore, AuthStoreModel } from '../ui/auth-store';
import { ViewStore, ViewStoreModel } from './view-store/view-store';

export interface UiStoreModel {
	authStore: AuthStoreModel;
	viewStore: ViewStoreModel;
}

export const UiStore = types
	.model('UiStore', {
		authStore: types.maybeNull(AuthStore),
		viewStore: types.maybeNull(ViewStore)
	})
	.actions(self => ({
		afterCreate() {
			self.authStore = AuthStore.create();
			self.viewStore = ViewStore.create();
		}
	}));
