export const layout: any = {
	id: 1,
	filters: [],
	partner_id: 779,
	reports_widgets: [
		{
			id: 1,
			widget: {
				description: 'Time from order creation to original ETA ',
				component_type: 2,
				title: 'TOTAL_ROUTE_TIME',
				trend: false,
				target_operator: 1,
				query: 7,
				options: { kpi: { units: 'time', target: 1200 } }
			},
			uuid: '1',
			position: 1
		},
		{
			id: 2,
			widget: {
				description: 'Time from original ETA to WP1 checkout',
				component_type: 2,
				title: 'TOTAL_RESTAURANT_TIME',
				trend: false,
				target_operator: 1,
				query: 8,
				options: { kpi: { units: 'time', target: 1200 } }
			},
			uuid: '2',
			position: 2
		},
		{
			id: 3,
			widget: {
				title: 'TOTAL_FULLFILMENT_TIME',
				component_type: 2,
				description: 'showing the total fulfillment in minutes time over the current period',
				query: 9,
				trend: true,
				options: { kpi: { target: 600, units: 'time' } }
			},
			uuid: '3',
			position: 3
		},
		{
			id: 4,
			widget: {
				description: 'Time from order creation to order completion',
				component_type: 2,
				title: 'TOTAL_TOTAL_TIME',
				trend: false,
				target_operator: 1,
				query: 10,
				options: { kpi: { units: 'time', target: 1200 } }
			},
			uuid: '4',
			position: 4
		},
		{
			id: 5,
			widget: {
				description: 'Unique customers over time',
				component_type: 0,
				title: 'CUSTOMERS_COUNT',
				trend: true,
				target_operator: 0,
				query: 2,
				options: { kpi: { units: 'number', target: 1200 } }
			},
			uuid: '5',
			position: 5
		},
		{
			id: 6,
			widget: {
				description: 'Active restaurant (Active= At least one order during the past 2 months)',
				component_type: 0,
				title: 'TEAMS_COUNT',
				trend: true,
				target_operator: 0,
				query: 3,
				options: { kpi: { units: 'number', target: 1200 } }
			},
			uuid: '6',
			position: 6
		},
		{
			id: 7,
			widget: {
				description: 'Total order value over time',
				component_type: 0,
				title: 'ORDERS_VALUE',
				trend: true,
				target_operator: 0,
				query: 1,
				options: { kpi: { units: '$', target: 1200 } },
				grouping_type: ['GROUP_BY_ORIGIN', 'GROUP_BY_FLEET']
			},
			uuid: '7',
			position: 7
		},
		{
			id: 8,
			widget: {
				description: 'Completed orders group by country',
				component_type: 5,
				title: 'ORDERS_COUNT',
				trend: false,
				target_operator: 0,
				query: 0,
				options: { kpi: { units: 'number', target: 1200 } },
				grouping_type: ['GROUP_BY_MERCHANT']
			},
			uuid: '8',
			position: 8
		},
		{
			id: 9,
			widget: {
				description: 'Completed orders by aggregators',
				component_type: 4,
				title: 'ORDERS_COUNT',
				trend: false,
				target_operator: 0,
				query: 0,
				options: {
					kpi: {
						units: 'number',
						target: 1200
					}
				},
				grouping_type: ['GROUP_BY_ORIGIN', 'GROUP_BY_FLEET']
			},
			uuid: '9',
			position: 9
		},
		{
			id: 10,
			widget: {
				description: 'Rate of cancelled orders',
				component_type: 0,
				title: 'CANCEL_ORDERS',
				trend: true,
				target_operator: 1,
				query: 11,
				options: {
					kpi: {
						units: 'percentage',
						target: 1200
					}
				}
			},
			uuid: '10',
			position: 10
		}
	]
};
