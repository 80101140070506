import { Instance, types } from 'mobx-state-tree';

import { TIME_FILTER_OPTION } from '../../features/time-filters/time-filters.consts';

export type TimeModel = Instance<typeof Time>;

export const Time = types
	.model('Time', {
		type: types.maybe(types.string),
		from: types.number,
		to: types.number
	})
	.actions(self => ({
		updateTimes(from: number, to: number, type?: TIME_FILTER_OPTION) {
			self.from = from;
			self.to = to;
			self.type = type ?? '';
		}
	}));
