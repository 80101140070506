import { MetricCounter, MetricMultiple } from '@bringg/react-components';
import { MetricType } from '@bringg/react-components/dist/components/metric-counter/metric-counter';
import Tooltip from '@bringg/react-components/dist/components/tooltip/tooltip';
import { COMPONENT_TYPES, TARGET_OPERATORS } from '@bringg/types';
import { Col, Row } from 'antd';
import _isNil from 'lodash/isNil';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetModel } from '../../stores/models/widget/widget';
import TargetPercentage from '../target-percentage/target-percentage';
import WidgetDetails from '../widget-details/widget-details';

interface Props {
	widget: WidgetModel;
	metricType: MetricType;
	targetType?: number;
}

const getTargetSign = (targetCompare: TARGET_OPERATORS) => (targetCompare === TARGET_OPERATORS.BIGGER ? '>' : '<');

const getValueClass = (targetCompare: TARGET_OPERATORS, target: number, value: number): string => {
	if (_isNil(target)) {
		return 'value';
	}

	if (targetCompare === TARGET_OPERATORS.BIGGER) {
		return value > target ? 'positive-value' : 'negative-value';
	}

	return value < target ? 'positive-value' : 'negative-value';
};

const getMetricComponent = (type, metricType, total, target_operator, optionTarget) => {
	switch (type) {
		case COMPONENT_TYPES.BIG_NUMBER:
			return (
				<Col span={18} className={getValueClass(target_operator, optionTarget, total)}>
					<MetricCounter metricType={metricType} metricValue={total} />
				</Col>
			);

		case COMPONENT_TYPES.MULTIPLE_QUERIES:
			return (
				<Col span={18} className="value">
					<MetricMultiple metricTypes={metricType} metricValues={total} />
				</Col>
			);

		default:
			return null;
	}
};

const NumberWidget = ({ widget, metricType, targetType }: Props) => {
	const { title, total, target_operator, options, description, trend, details_query, type } = widget;
	const { t } = useTranslation();

	return (
		<div className="number-widget">
			<Tooltip key={`${title}-tooltip`} title={description} placement="top">
				<Row className="top-row">
					<Col span={18} className="title">
						{t(title)}
					</Col>
					<Col span={6} className="target-percentage">
						{!_isNil(trend) && (
							<TargetPercentage
								trend={trend}
								positiveTarget={target_operator === TARGET_OPERATORS.BIGGER}
							/>
						)}
					</Col>
				</Row>
				<Row className="bottom-row">
					{getMetricComponent(type, metricType, total, target_operator, options.target)}

					{!_isNil(options.target) && (
						<Col span={6} className="target">
							<div className="target-title">{t('Target')}:</div>
							<div className="target-value">
								{getTargetSign(target_operator)}

								<MetricCounter
									className="metric-value"
									metricType={metricType}
									metricValue={options.target}
									targetType={targetType}
								/>
							</div>
						</Col>
					)}
				</Row>
				{details_query && <WidgetDetails detailsQuery={details_query} />}
			</Tooltip>
		</div>
	);
};

export default NumberWidget;
