import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../recipes/use-store';

export const AutoRefresh = () => {
	const { t } = useTranslation();
	const {
		uiStore: { viewStore }
	} = useStore();

	return useObserver(() => {
		const { autoRefresh } = viewStore;

		return (
			<div className="auto-refresh">
				<div className="auto-refresh-title">{t('AUTO_REFRESH')}:</div>
				<div className="auto-refresh-text">
					{autoRefresh ? (
						<span className="auto-refresh-button" onClick={() => viewStore.updateAutoRefresh(false)}>
							{t('ON')}
						</span>
					) : (
						<span className="auto-refresh-button" onClick={() => viewStore.updateAutoRefresh(true)}>
							{t('OFF')}
						</span>
					)}
				</div>
			</div>
		);
	});
};
