import { COMPONENT_TYPES, FILTER_TYPES, WIDGET_GROUPING_TYPES } from '@bringg/types';
import _first from 'lodash/first';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _toPairs from 'lodash/toPairs';
import { destroy, flow, getEnv, getRoot } from 'mobx-state-tree';
import moment from 'moment';

import notifyError from '../../../services/notify-error';
import { RootStoreEnv } from '../../helpers/create-store';
import { RootStoreModel } from '../../root-store';
import { GroupingData } from '../grouping-data';

export const DEFAULT_POLLING_INTERVAL = 30000;
export const NO_GROUPING = 'All';
const WIDGET_TYPE_TO_OPTIONS = {
	[COMPONENT_TYPES.PIE]: [],
	[COMPONENT_TYPES.BIG_NUMBER]: [NO_GROUPING],
	[COMPONENT_TYPES.TIME_GRAPH]: [NO_GROUPING],
	[COMPONENT_TYPES.STACKED_SEGMENT_BAR]: [NO_GROUPING],
	[COMPONENT_TYPES.UNSTACKED_SEGMENT_BAR]: [NO_GROUPING],
	[COMPONENT_TYPES.NON_TIME_BASED_STACKED_SEGMENT_BAR]: [NO_GROUPING]
};
const GET_PAIR_KEY = '[0]';
const GET_PAIR_VALUE = '[1]';

const getValueByComponent = (widgetData: any, type: COMPONENT_TYPES) => {
	if (type === COMPONENT_TYPES.PIE) {
		return widgetData.total;
	}

	return _map(widgetData.values, GET_PAIR_VALUE);
};

const widgetActions = self => {
	const setPollingId = (id: number) => {
		self.pollingId = id;
	};
	const setExtraData = (data, trend: number, total?: number | number[]) => {
		if (data) {
			const exists = self.grouping_data.find(currentData => currentData.grouping === data.grouping);

			if (exists) {
				destroy(exists);
			}

			self.grouping_data.push(data);
		}

		self.trend = trend;
		self.total = total;
	};
	const setDetails = detailsResult => {
		if (self.details_query) {
			const { errorValues, warningValues } = convertDetailsData(detailsResult);
			self.details_query.error_values = errorValues;
			self.details_query.warning_values = warningValues;
		}
	};
	const resetGroupingData = () => {
		self.total = null;
		self.trend = null;
		self.grouping_data.clear();
	};
	const setLoading = (isLoading: boolean) => {
		self.isLoading = isLoading;
	};
	const updateGroupingTypes = (groupingType: string[]) => {
		self.grouping_types = groupingType;
	};
	const updateUuid = (uuid: string) => {
		self.uuid = uuid;
	};

	const convertDetailsData = detailsData => {
		if (_isEmpty(detailsData)) {
			return { errorValues: [], warningValues: [] };
		}

		const errorValues = Object.keys(
			_get(
				detailsData.find(data => data.name === 'NO_DATA'),
				'values'
			) || {}
		);
		const warningValues = Object.keys(
			_get(
				detailsData.find(data => data.name === 'PARTIAL'),
				'values'
			) || {}
		);
		return { errorValues, warningValues };
	};

	const convertResponse = (response, groupType: string = NO_GROUPING) => {
		if (!response.values) {
			return null;
		}
		const valuesByName = {};
		const values = _map(response.values, value => {
			return { ...value, values: sortByKeys(value.values) };
		});

		values.forEach(value => (valuesByName[value.name || NO_GROUPING] = getValueByComponent(value, self.type)));

		return GroupingData.create({
			keys: _map(_get(_first(values), 'values', []), GET_PAIR_KEY),
			total: response.total,
			grouping: groupType,
			valuesByName
		});
	};

	const sortByKeys = (object): [string, any][] => {
		const objectAsPairs = _toPairs(object);
		return _sortBy(objectAsPairs, 0);
	};

	const fetchWidget = flow(function* (request, groupType) {
		const viewStore = getRoot<RootStoreModel>(self).uiStore.viewStore;
		let response;

		try {
			if (viewStore.isDemoUser) {
				const demoData = getEnv<RootStoreEnv>(self).demoData;

				response = demoData.getReport(self.uuid, request.filters[FILTER_TYPES.TIME_RANGE], groupType);
			} else {
				const env = getEnv<RootStoreEnv>(self);
				const dashboardSdk = env.dashboardSdk.sdk;

				response = yield env.requestQueue.executeRequest(
					dashboardSdk.reports.getReport(self.uuid, {
						...request,
						grouping_type: groupType === NO_GROUPING ? null : (groupType as WIDGET_GROUPING_TYPES)
					})
				);
			}

			// @ts-ignore - need to fix response types
			setExtraData(convertResponse(response, groupType), response.trend, response.total);
			setDetails(response.detailsResult);
		} catch (e) {
			notifyError(getEnv<RootStoreEnv>(self), new Error('Failed fetch widget'), {
				error: JSON.stringify(e),
				request,
				groupType,
				widgetId: self.uuid
			});
			console.error(`${self.uuid} with grouping ${groupType} could not be fetched`, e);
		} finally {
			if (self.isLoading) {
				setLoading(false);
			}
		}
	});

	const fetchGroups = flow(function* (request) {
		const firstGroup = self.grouping_types[0];

		yield fetchWidget(request, firstGroup);

		for (let i = 1; i < self.grouping_types.length; i++) {
			fetchWidget(request, self.grouping_types[i]);
		}
	});

	const buildRequest = () => {
		const viewStore = getRoot<RootStoreModel>(self).uiStore.viewStore;

		return {
			filters: {
				[FILTER_TYPES.TIME_RANGE]: {
					from: moment(viewStore.selectedTimeFilter.from).utc(true).valueOf(),
					to: moment(viewStore.selectedTimeFilter.to).utc(true).valueOf()
				},
				[FILTER_TYPES.COMPARE_RANGE]: {
					from: viewStore.selectedCompareTimeFilter.from,
					to: viewStore.selectedCompareTimeFilter.to
				},
				...(viewStore.selectedFilters.size && viewStore.selectedFilters.toJSON())
			},
			...(viewStore.selectedMerchantId && { merchant_ids: [viewStore.selectedMerchantId] }),
			...(viewStore.selectedTeamId && { team_ids: [viewStore.selectedTeamId] })
		};
	};

	const fetchData = () => {
		const request = buildRequest();

		fetchGroups(request);
	};

	function afterCreate() {
		const initialGrouping = WIDGET_TYPE_TO_OPTIONS[self.type] || [];

		updateGroupingTypes([...initialGrouping, ...self.grouping_types]);
	}

	const startPolling = () => {
		const pollingId: number = window.setInterval(() => {
			self.fetchData();
		}, DEFAULT_POLLING_INTERVAL);

		setPollingId(pollingId);
	};

	const stopPolling = () => {
		clearInterval(self.pollingId);
		setPollingId(null);
	};

	return {
		updateUuid,
		startPolling,
		stopPolling,
		resetGroupingData,
		convertResponse,
		afterCreate,
		setExtraData,
		setDetails,
		setLoading,
		fetchData
	};
};

export { widgetActions };
