import { endOfDay, startOfDay } from 'date-fns';
import { Moment } from 'moment';

export const formatAsSystem = (moment: Moment) => Intl.DateTimeFormat().format(moment.toDate());

export const getDateRange = ([dateStart, dateEnd]: [Date, Date]) => ({
	startDate: startOfDay(dateStart),
	endDate: endOfDay(dateEnd)
});

export const getToday = () => {
	const today = new Date();
	today.setHours(0);
	today.setMinutes(0);
	today.setSeconds(0);
	today.setMilliseconds(0);
	return today;
};
