import { Space } from 'antd';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { DatePicker } from '../../../components/date-picker/date-picker';
import useOutsideClick from '../../../recipes/use-outside-click';
import { EmptyFn, RenderFn } from '../../../types/functions.type';
import { getToday } from '../../../util/date.util';
import { PICKER_DATE_FORMAT } from '../time-filters.consts';
import { FromToDateRange } from '../time-filters.type';
import { getRangePickerClassName, updateTimeFiltersInStore } from '../time-filters.utils';

const { RangePicker } = DatePicker;

interface Props {
	title: string;
	updateRangeAction: (startDate: Date, endDate: Date) => void;
	footer: RenderFn;
	isOpen: boolean;
	value: FromToDateRange;
	onPickerInputClick?: EmptyFn;
	onOutsideClick?: EmptyFn;
	onCalendarRangeSet?: EmptyFn;
	onSelect?: EmptyFn;
}

export const DateFilterTimePicker = ({
	title,
	updateRangeAction,
	footer,
	isOpen,
	onPickerInputClick,
	onCalendarRangeSet,
	onOutsideClick,
	value,
	onSelect
}: Props) => {
	const pickerChangedTimes = useRef(0);
	const pickerInput = useRef(null);
	const disabledDate = useCallback((date: Date) => date > getToday(), []);
	const rangePickerClassName = getRangePickerClassName(title);

	const openCalendar = useCallback(() => {
		if (!isOpen) {
			onPickerInputClick();
		}
	}, [isOpen, onPickerInputClick]);

	useEffect(() => {
		const pickerInputNode = ReactDOM.findDOMNode(pickerInput.current);

		pickerInputNode.addEventListener('click', openCalendar);

		return () => {
			pickerInputNode.removeEventListener('click', openCalendar);
		};
	}, [isOpen, openCalendar]);

	useOutsideClick(rangePickerClassName, onOutsideClick);

	const handleOnChange = (selectedDates: FromToDateRange) => {
		updateTimeFiltersInStore(selectedDates, updateRangeAction);

		if (onSelect) {
			onSelect();
		}
	};

	const handleOnCalendarChange = () => {
		pickerChangedTimes.current = pickerChangedTimes.current + 1;
		if (pickerChangedTimes.current === 2) {
			pickerChangedTimes.current = 0;
			onCalendarRangeSet();
		}
	};

	return (
		<Space direction="horizontal" className="time-filter">
			<span className="time-filter-title">{title} :</span>
			<RangePicker
				ref={pickerInput}
				dropdownClassName={rangePickerClassName}
				format={PICKER_DATE_FORMAT}
				open={isOpen}
				bordered={false}
				allowClear={false}
				renderExtraFooter={footer}
				disabledDate={disabledDate}
				value={value}
				onChange={handleOnChange}
				onCalendarChange={handleOnCalendarChange}
			/>
		</Space>
	);
};
