import { FILTER_TYPES } from '@bringg/types';
import { Instance, types } from 'mobx-state-tree';

export interface FilterValuesModel extends Instance<typeof FilterValues> {}

export const FilterValues = types.model('FilterValues', {
	id: types.union(types.string, types.number),
	name: types.string
});

export interface FilterModel extends Instance<typeof Filter> {}

export const Filter = types.model('Filter', {
	filter_type: types.enumeration<FILTER_TYPES>(Object.values(FILTER_TYPES)),
	values: types.array(FilterValues)
});
