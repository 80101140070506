import ErrorBoundaryPage from '@bringg/react-components/dist/components/error-boundary-page/error-boundary-page';
import Spinner from '@bringg/react-components/dist/components/spinner/spinner';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import Layout from './features/layout/layout';
import Login from './features/login/login';
import { useInject } from './recipes/use-inject';
import { authStoreMap } from './stores/helpers/store-maps';

enum AppState {
	LoginProcess,
	LoginProcessDone,
	LoggedIn
}

const App = () => {
	const [appState, setAppState] = useState<AppState>(AppState.LoginProcess);
	const { authStore } = useInject(authStoreMap);
	const { isLoggedIn, reportUuid } = authStore;

	useEffect(() => {
		const asyncInitSdk = async () => {
			if (!authStore.isUsedAsIframe) {
				await authStore.initSdkFromEnvironment();
			} else if (!authStore.isLoggedIn) {
				await authStore.initSdkFromWindow();
			}

			setAppState(AppState.LoginProcessDone);
		};

		if (appState === AppState.LoginProcess) {
			asyncInitSdk();
		}
	}, [authStore, appState]);

	useEffect(() => {
		if (isLoggedIn) {
			setAppState(AppState.LoggedIn);
		} else if (appState !== AppState.LoginProcess) {
			setAppState(AppState.LoginProcessDone);
		}
	}, [isLoggedIn, appState]);

	return (
		<ErrorBoundaryPage>
			<div className="bringg-bi">
				{appState === AppState.LoginProcess && <Spinner className="main-spinner" />}
				{appState === AppState.LoginProcessDone && <Login />}
				{appState === AppState.LoggedIn && !reportUuid && <Layout />}
				{appState === AppState.LoggedIn && reportUuid && <div>Downloading File!</div>}
			</div>
		</ErrorBoundaryPage>
	);
};

export default observer(App);
