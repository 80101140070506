import { TIME_FILTER_OPTION } from '../../features/time-filters/time-filters.consts';

export const TotalReportsByUuidAndTime = new Map([
	[
		'1',
		new Map([
			[TIME_FILTER_OPTION.LAST_24H, { total: 378.03, trend: -0.05 }],
			[TIME_FILTER_OPTION.LAST_7D, { total: 303, trend: -0.06 }],
			[TIME_FILTER_OPTION.LAST_MONTH, { total: 603.45, trend: -0.07 }],
			[TIME_FILTER_OPTION.LAST_YEAR, { total: 802.33 }]
		])
	],

	[
		'2',
		new Map([
			[TIME_FILTER_OPTION.LAST_24H, { total: 472, trend: 0.03 }],
			[TIME_FILTER_OPTION.LAST_7D, { total: 553.55, trend: 0.04 }],
			[TIME_FILTER_OPTION.LAST_MONTH, { total: 558.43, trend: 0.09 }],
			[TIME_FILTER_OPTION.LAST_YEAR, { total: 602.22, trend: 0.1 }]
		])
	],

	[
		'3',
		new Map([
			[TIME_FILTER_OPTION.LAST_24H, { total: 112.29, trend: -0.06 }],
			[TIME_FILTER_OPTION.LAST_7D, { total: 122.12, trend: -0.1 }],
			[TIME_FILTER_OPTION.LAST_MONTH, { total: 227.55, trend: -0.06 }],
			[TIME_FILTER_OPTION.LAST_YEAR, { total: 300.83, trend: -0.07 }]
		])
	],

	[
		'4',
		new Map([
			[TIME_FILTER_OPTION.LAST_24H, { total: 900.19, trend: 0.02 }],
			[TIME_FILTER_OPTION.LAST_7D, { total: 1043.82, trend: 0.04 }],
			[TIME_FILTER_OPTION.LAST_MONTH, { total: 1201.73, trend: 0.09 }],
			[TIME_FILTER_OPTION.LAST_YEAR, { total: 2522.112, trend: 0.03 }]
		])
	],
	[
		'5',
		new Map([
			[
				TIME_FILTER_OPTION.LAST_24H,
				{
					values: [
						{
							name: '',
							values: {
								'1579377600000': 1977,
								'1579381200000': 2011,
								'1579384800000': 2408,
								'1579388400000': 3216,
								'1579392000000': 4112,
								'1579395600000': 3893,
								'1579399200000': 2784,
								'1579402800000': 1707,
								'1579406400000': 1046,
								'1579410000000': 521,
								'1579413600000': 362,
								'1579417200000': 235,
								'1579420800000': 186,
								'1579424400000': 131,
								'1579428000000': 119,
								'1579431600000': 150,
								'1579435200000': 226,
								'1579438800000': 395,
								'1579442400000': 689,
								'1579446000000': 1450,
								'1579449600000': 1990,
								'1579453200000': 2354,
								'1579456800000': 2421,
								'1579460400000': 2276,
								'1579464000000': 1493
							}
						}
					],
					total: 34064,
					trend: -0.02
				}
			],
			[
				TIME_FILTER_OPTION.LAST_7D,
				{
					values: [
						{
							name: '',
							values: {
								'1578787200000': 0,
								'1578873600000': 30343,
								'1578960000000': 26964,
								'1579046400000': 30731,
								'1579132800000': 33148,
								'1579219200000': 34323,
								'1579305600000': 33079,
								'1579392000000': 25695
							}
						}
					],
					total: 182422,
					trend: 0.09
				}
			],
			[
				TIME_FILTER_OPTION.LAST_MONTH,
				{
					values: [
						{
							name: '',
							values: {
								'1576800000000': 0,
								'1576886400000': 36207,
								'1576972800000': 34577,
								'1577059200000': 32630,
								'1577145600000': 25988,
								'1577232000000': 11534,
								'1577318400000': 30552,
								'1577404800000': 30526,
								'1577491200000': 28374,
								'1577577600000': 27390,
								'1577664000000': 29149,
								'1577750400000': 24073,
								'1577836800000': 12351,
								'1577923200000': 28482,
								'1578009600000': 27212,
								'1578096000000': 28178,
								'1578182400000': 29700,
								'1578268800000': 28792,
								'1578355200000': 24488,
								'1578441600000': 25713,
								'1578528000000': 28668,
								'1578614400000': 30508,
								'1578700800000': 33898,
								'1578787200000': 34459,
								'1578873600000': 30343,
								'1578960000000': 26964,
								'1579046400000': 30731,
								'1579132800000': 33148,
								'1579219200000': 34323,
								'1579305600000': 33079,
								'1579392000000': 25743
							}
						}
					],
					total: 580806,
					trend: 0.05,
					uuid: '98eeca9b-fba8-4675-b9dd-9f1e47af9b44'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_YEAR,
				{
					values: [
						{
							name: '',
							values: {
								'1547424000000': 12637,
								'1548028800000': 66192,
								'1548633600000': 75473,
								'1549238400000': 82091,
								'1549843200000': 83722,
								'1550448000000': 85052,
								'1551052800000': 88712,
								'1551657600000': 95965,
								'1552262400000': 95189,
								'1552867200000': 103131,
								'1553472000000': 122098,
								'1554076800000': 106074,
								'1554681600000': 107977,
								'1555286400000': 99721,
								'1555891200000': 108868,
								'1556496000000': 118832,
								'1557100800000': 109033,
								'1557705600000': 133541,
								'1558310400000': 114365,
								'1558915200000': 132325,
								'1559520000000': 121839,
								'1560124800000': 120324,
								'1560729600000': 128392,
								'1561334400000': 160064,
								'1561939200000': 145070,
								'1562544000000': 140899,
								'1563148800000': 148815,
								'1563753600000': 153699,
								'1564358400000': 163348,
								'1564963200000': 161511,
								'1565568000000': 171795,
								'1566172800000': 174482,
								'1566777600000': 192390,
								'1567382400000': 162553,
								'1567987200000': 158391,
								'1568592000000': 163436,
								'1569196800000': 201367,
								'1569801600000': 179932,
								'1570406400000': 167226,
								'1571011200000': 174211,
								'1571616000000': 187311,
								'1572220800000': 196380,
								'1572825600000': 169369,
								'1573430400000': 178462,
								'1574035200000': 193173,
								'1574640000000': 193544,
								'1575244800000': 161539,
								'1575849600000': 168426,
								'1576454400000': 177935,
								'1577059200000': 158918,
								'1577664000000': 153421,
								'1578268800000': 167910,
								'1578873600000': 182745
							}
						}
					],
					total: 2959697,
					trend: 7.79,
					uuid: '98eeca9b-fba8-4675-b9dd-9f1e47af9b44'
				}
			]
		])
	],
	[
		'6',
		new Map([
			[
				TIME_FILTER_OPTION.LAST_24H,
				{
					values: [
						{
							name: '',
							values: {
								'1579381200000': 672,
								'1579384800000': 719,
								'1579388400000': 747,
								'1579392000000': 795,
								'1579395600000': 724,
								'1579399200000': 584,
								'1579402800000': 367,
								'1579406400000': 232,
								'1579410000000': 144,
								'1579413600000': 101,
								'1579417200000': 83,
								'1579420800000': 68,
								'1579424400000': 51,
								'1579428000000': 63,
								'1579431600000': 108,
								'1579435200000': 163,
								'1579438800000': 217,
								'1579442400000': 369,
								'1579446000000': 550,
								'1579449600000': 640,
								'1579453200000': 713,
								'1579456800000': 697,
								'1579460400000': 687,
								'1579464000000': 667,
								'1579467600000': 285
							}
						}
					],
					total: 943,
					trend: -0.02,
					uuid: '2f9404a5-046c-4115-b20a-0a26a5f52830'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_7D,
				{
					values: [
						{
							name: '',
							values: {
								'1578787200000': 0,
								'1578873600000': 959,
								'1578960000000': 974,
								'1579046400000': 963,
								'1579132800000': 979,
								'1579219200000': 982,
								'1579305600000': 969,
								'1579392000000': 931
							}
						}
					],
					total: 1005,
					trend: 0.02,
					uuid: '2f9404a5-046c-4115-b20a-0a26a5f52830'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_MONTH,
				{
					values: [
						{
							name: '',
							values: {
								'1576800000000': 0,
								'1576886400000': 926,
								'1576972800000': 907,
								'1577059200000': 929,
								'1577145600000': 906,
								'1577232000000': 665,
								'1577318400000': 932,
								'1577404800000': 926,
								'1577491200000': 934,
								'1577577600000': 909,
								'1577664000000': 939,
								'1577750400000': 931,
								'1577836800000': 624,
								'1577923200000': 950,
								'1578009600000': 950,
								'1578096000000': 943,
								'1578182400000': 926,
								'1578268800000': 948,
								'1578355200000': 963,
								'1578441600000': 956,
								'1578528000000': 966,
								'1578614400000': 961,
								'1578700800000': 969,
								'1578787200000': 950,
								'1578873600000': 959,
								'1578960000000': 974,
								'1579046400000': 963,
								'1579132800000': 979,
								'1579219200000': 982,
								'1579305600000': 969,
								'1579392000000': 931
							}
						}
					],
					total: 1011,
					trend: 0.02,
					uuid: '2f9404a5-046c-4115-b20a-0a26a5f52830'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_YEAR,
				{
					values: [
						{
							name: '',
							values: {
								'1547424000000': 362,
								'1548028800000': 404,
								'1548633600000': 415,
								'1549238400000': 419,
								'1549843200000': 422,
								'1550448000000': 423,
								'1551052800000': 428,
								'1551657600000': 428,
								'1552262400000': 435,
								'1552867200000': 440,
								'1553472000000': 446,
								'1554076800000': 463,
								'1554681600000': 486,
								'1555286400000': 521,
								'1555891200000': 525,
								'1556496000000': 529,
								'1557100800000': 535,
								'1557705600000': 544,
								'1558310400000': 559,
								'1558915200000': 580,
								'1559520000000': 589,
								'1560124800000': 600,
								'1560729600000': 609,
								'1561334400000': 627,
								'1561939200000': 643,
								'1562544000000': 674,
								'1563148800000': 687,
								'1563753600000': 737,
								'1564358400000': 759,
								'1564963200000': 783,
								'1565568000000': 795,
								'1566172800000': 825,
								'1566777600000': 854,
								'1567382400000': 876,
								'1567987200000': 878,
								'1568592000000': 881,
								'1569196800000': 886,
								'1569801600000': 896,
								'1570406400000': 915,
								'1571011200000': 912,
								'1571616000000': 920,
								'1572220800000': 929,
								'1572825600000': 932,
								'1573430400000': 936,
								'1574035200000': 940,
								'1574640000000': 937,
								'1575244800000': 953,
								'1575849600000': 961,
								'1576454400000': 977,
								'1577059200000': 953,
								'1577664000000': 979,
								'1578268800000': 985,
								'1578873600000': 1005
							}
						}
					],
					total: 1048,
					trend: 1.72,
					uuid: '2f9404a5-046c-4115-b20a-0a26a5f52830'
				}
			]
		])
	],
	[
		'7',
		new Map([
			[
				TIME_FILTER_OPTION.LAST_24H,
				{
					values: [
						{
							name: '',
							values: {
								'1579381200000': 7437912.74,
								'1579384800000': 6856709.32,
								'1579388400000': 8216505.81,
								'1579392000000': 14667058.69,
								'1579395600000': 15749801.16,
								'1579399200000': 13000723.34,
								'1579402800000': 8914068.48,
								'1579406400000': 5097510.93,
								'1579410000000': 1744136.9,
								'1579413600000': 612838.41,
								'1579417200000': 309296.14,
								'1579420800000': 93259.58,
								'1579424400000': 11124.78,
								'1579428000000': 10255.32,
								'1579431600000': 135040.5,
								'1579435200000': 680044.74,
								'1579438800000': 2440716.86,
								'1579442400000': 2800886.31,
								'1579446000000': 4926956.47,
								'1579449600000': 4919519.67,
								'1579453200000': 8400334.21,
								'1579456800000': 11421313.73,
								'1579460400000': 12135195.7,
								'1579464000000': 9401961.02,
								'1579467600000': 6459653.67
							}
						}
					],
					total: 146442824.48,
					trend: 0.21,
					uuid: '0ab774e9-ab85-4ee7-96e5-2c705b21c985'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_7D,
				{
					values: [
						{
							name: '',
							values: {
								'1578873600000': 87494038.67,
								'1578960000000': 71337909.85,
								'1579046400000': 74727099.2,
								'1579132800000': 87399660.43,
								'1579219200000': 93065609.77,
								'1579305600000': 118848938.18,
								'1579392000000': 123823987.81
							}
						}
					],
					total: 656697243.91,
					trend: -0.05,
					uuid: '0ab774e9-ab85-4ee7-96e5-2c705b21c985'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_MONTH,
				{
					values: [
						{
							name: '',
							values: {
								'1576886400000': 129765013.72,
								'1576972800000': 133525628.9,
								'1577059200000': 98306098.45,
								'1577145600000': 87345198.7,
								'1577232000000': 62076769.45,
								'1577318400000': 99450748.24,
								'1577404800000': 88075369.96,
								'1577491200000': 98193028.9,
								'1577577600000': 106815680.26,
								'1577664000000': 92218152.39,
								'1577750400000': 88542980.61,
								'1577836800000': 95774551.85,
								'1577923200000': 109615374.13,
								'1578009600000': 90811555.1,
								'1578096000000': 90172065.89,
								'1578182400000': 107512107.35,
								'1578268800000': 105364770.44,
								'1578355200000': 80543367.76,
								'1578441600000': 73157045.4,
								'1578528000000': 82432485.69,
								'1578614400000': 92548588.18,
								'1578700800000': 118522513.05,
								'1578787200000': 140531811.78,
								'1578873600000': 87494038.67,
								'1578960000000': 71337909.85,
								'1579046400000': 74727099.2,
								'1579132800000': 87399660.43,
								'1579219200000': 93065609.77,
								'1579305600000': 118848938.18,
								'1579392000000': 123823987.81
							}
						}
					],
					total: 2927998150.14,
					trend: -0.1,
					uuid: '0ab774e9-ab85-4ee7-96e5-2c705b21c985'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_YEAR,
				{
					values: [
						{
							name: '',
							values: {
								'1547424000000': 92574719.6,
								'1548028800000': 433092670.4,
								'1548633600000': 483706164.7,
								'1549238400000': 475167486.35,
								'1549843200000': 464297104.42,
								'1550448000000': 515858470.35,
								'1551052800000': 524801653,
								'1551657600000': 504361637.9,
								'1552262400000': 533949716.5,
								'1552867200000': 541023066.3,
								'1553472000000': 654308185.49,
								'1554076800000': 563983979.17,
								'1554681600000': 586148587.36,
								'1555286400000': 574342480.39,
								'1555891200000': 571330356.19,
								'1556496000000': 751097637.11,
								'1557100800000': 594721887.88,
								'1557705600000': 599186557.58,
								'1558310400000': 573218469.21,
								'1558915200000': 658530520.72,
								'1559520000000': 649619091.46,
								'1560124800000': 595732622.36,
								'1560729600000': 672971367.14,
								'1561334400000': 763343574.34,
								'1561939200000': 682749502.27,
								'1562544000000': 623650011.37,
								'1563148800000': 663180808.03,
								'1563753600000': 668818980.54,
								'1564358400000': 778027379.33,
								'1564963200000': 765653298.81,
								'1565568000000': 728434419.59,
								'1566172800000': 796111787.42,
								'1566777600000': 836698145.42,
								'1567382400000': 788999677.75,
								'1567987200000': 800325279.14,
								'1568592000000': 829532211.33,
								'1569196800000': 927057531.62,
								'1569801600000': 734732069.44,
								'1570406400000': 748218244.65,
								'1571011200000': 798124171.9,
								'1571616000000': 810027108.24,
								'1572220800000': 817440656.62,
								'1572825600000': 822705345.26,
								'1573430400000': 859906896.26,
								'1574035200000': 758216611.31,
								'1574640000000': 766468227.3,
								'1575244800000': 727263367.99,
								'1575849600000': 768908390.86,
								'1576454400000': 755379783.83,
								'1577059200000': 640262893.97,
								'1577664000000': 674646787.33,
								'1578268800000': 693100582.31,
								'1578873600000': 657220284.16
							}
						}
					],
					total: 35299228459.98,
					trend: 8.61,
					uuid: '0ab774e9-ab85-4ee7-96e5-2c705b21c985'
				}
			]
		])
	],
	[
		'7-GROUP_BY_ORIGIN',
		new Map([
			[
				TIME_FILTER_OPTION.LAST_24H,
				{
					values: [
						{
							name: 'origin1',
							values: {
								'1579381200000': 333953.13,
								'1579384800000': 327200.89,
								'1579388400000': 302770.52,
								'1579392000000': 567723.28,
								'1579395600000': 652047.86,
								'1579399200000': 974149.86,
								'1579402800000': 700756.55,
								'1579406400000': 724886.5,
								'1579410000000': 520064.9,
								'1579413600000': 264767.92,
								'1579417200000': 170793.05,
								'1579420800000': 87077.79,
								'1579424400000': 5834.79,
								'1579428000000': 4292.38,
								'1579431600000': 6588.81,
								'1579435200000': 8196.99,
								'1579438800000': 34444.71,
								'1579442400000': 115538.32,
								'1579446000000': 231034.08000000002,
								'1579449600000': 325178.73,
								'1579453200000': 351427.64,
								'1579456800000': 383556.48,
								'1579460400000': 527755.7,
								'1579464000000': 507422.16,
								'1579467600000': 515495.53
							}
						},
						{
							name: 'origin2',
							values: {
								'1579381200000': 5244,
								'1579384800000': 3904.1,
								'1579388400000': 3566,
								'1579392000000': 3458.6,
								'1579395600000': 3384.6,
								'1579399200000': 3443,
								'1579402800000': 4035.2,
								'1579406400000': 3394.2,
								'1579410000000': 3179.5,
								'1579413600000': 3321.3,
								'1579417200000': 3551.4,
								'1579420800000': 3584.7,
								'1579424400000': 3408.2,
								'1579428000000': 3293.7,
								'1579431600000': 3438.7,
								'1579435200000': 3648.6,
								'1579438800000': 3438.5,
								'1579442400000': 3058.6,
								'1579446000000': 3282.7,
								'1579449600000': 2909.5,
								'1579453200000': 2842.2,
								'1579456800000': 2532.9,
								'1579460400000': 2169,
								'1579464000000': 2418.4,
								'1579467600000': 1938
							}
						},
						{
							name: 'origin3',
							values: {
								'1579381200000': 726411.64,
								'1579384800000': 859057.84,
								'1579388400000': 1134696.8,
								'1579392000000': 1611696.93,
								'1579395600000': 1146144.25,
								'1579399200000': 886035.85,
								'1579402800000': 462307.75,
								'1579406400000': 292184.77,
								'1579410000000': 113097,
								'1579413600000': 2077,
								'1579417200000': 1097,
								'1579420800000': 626,
								'1579424400000': 897,
								'1579428000000': 2171,
								'1579431600000': 2472,
								'1579435200000': 36656.55,
								'1579438800000': 110570.15,
								'1579442400000': 305842.95,
								'1579446000000': 734681.9,
								'1579449600000': 1185544.69,
								'1579453200000': 1497856.56,
								'1579456800000': 1540511.82,
								'1579460400000': 1018614.36,
								'1579464000000': 822165.39,
								'1579467600000': 655535.79
							}
						},
						{
							name: 'origin4',
							values: {
								'1579381200000': 457.2,
								'1579384800000': 654.7,
								'1579388400000': 2077.2,
								'1579392000000': 1650.5,
								'1579395600000': 1273.7,
								'1579399200000': 653.3,
								'1579402800000': 137.3,
								'1579406400000': 0,
								'1579410000000': 0,
								'1579413600000': 0,
								'1579417200000': 0,
								'1579420800000': 0,
								'1579424400000': 0,
								'1579428000000': 87.2,
								'1579431600000': 69.7,
								'1579435200000': 97,
								'1579438800000': 0,
								'1579442400000': 132.1,
								'1579446000000': 153.8,
								'1579449600000': 311.1,
								'1579453200000': 716.9,
								'1579456800000': 684.3,
								'1579460400000': 236.2,
								'1579464000000': 231.5,
								'1579467600000': 474
							}
						},
						{
							name: 'origin5',
							values: {
								'1579381200000': 33766.1,
								'1579384800000': 51005.7,
								'1579388400000': 70816.9,
								'1579392000000': 73778.3,
								'1579395600000': 48246.3,
								'1579399200000': 30683.2,
								'1579402800000': 12226.1,
								'1579406400000': 5876.1,
								'1579410000000': 848.4,
								'1579413600000': 46.2,
								'1579417200000': 0,
								'1579420800000': 0,
								'1579424400000': 0,
								'1579428000000': 34.2,
								'1579431600000': 0,
								'1579435200000': 328.6,
								'1579438800000': 711.5,
								'1579442400000': 4674.4,
								'1579446000000': 18310.4,
								'1579449600000': 25336.1,
								'1579453200000': 28179.7,
								'1579456800000': 24354.3,
								'1579460400000': 25177.8,
								'1579464000000': 30081.3,
								'1579467600000': 36954.9
							}
						}
					],
					total: 147146581.33,
					trend: 0.22,
					uuid: '0ab774e9-ab85-4ee7-96e5-2c705b21c985'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_7D,
				{
					values: [
						{
							name: 'origin1',
							values: {
								'1578873600000': 6168173.74,
								'1578960000000': 5512541.98,
								'1579046400000': 5949904.54,
								'1579132800000': 6472542.83,
								'1579219200000': 7191846.5600000005,
								'1579305600000': 8279989.98,
								'1579392000000': 7677978.26
							}
						},
						{
							name: 'origin2',
							values: {
								'1578873600000': 4494917.9,
								'1578960000000': 3288181.7,
								'1579046400000': 3457594.2,
								'1579132800000': 3653061,
								'1579219200000': 2560114.4,
								'1579305600000': 130976.8,
								'1579392000000': 69790.1
							}
						},
						{
							name: 'origin3',
							values: {
								'1578873600000': 12095548.96,
								'1578960000000': 10358957.08,
								'1579046400000': 10654979.99,
								'1579132800000': 12490257.31,
								'1579219200000': 13205315.19,
								'1579305600000': 13968785.12,
								'1579392000000': 12512136.7
							}
						},
						{
							name: 'origin2 Eats',
							values: {
								'1578873600000': 119194,
								'1578960000000': 38427,
								'1579046400000': 81033,
								'1579132800000': 100055,
								'1579219200000': 53466,
								'1579305600000': 0,
								'1579392000000': 0
							}
						},
						{
							name: 'origin4',
							values: {
								'1578873600000': 10470.2,
								'1578960000000': 7556,
								'1579046400000': 8684.4,
								'1579132800000': 8063.9,
								'1579219200000': 10073.3,
								'1579305600000': 9826.8,
								'1579392000000': 7023.8
							}
						},
						{
							name: 'origin5',
							values: {
								'1578873600000': 410032.3,
								'1578960000000': 340789.5,
								'1579046400000': 358785.8,
								'1579132800000': 386427,
								'1579219200000': 430178.4,
								'1579305600000': 471585.4,
								'1579392000000': 368434.5
							}
						}
					],
					total: 657599543.28,
					trend: -0.05,
					uuid: '0ab774e9-ab85-4ee7-96e5-2c705b21c985'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_MONTH,
				{
					values: [
						{
							name: 'origin1',
							values: {
								'1576886400000': 8726165.1,
								'1576972800000': 7300145.84,
								'1577059200000': 6881183.45,
								'1577145600000': 6891879.55,
								'1577232000000': 2179216.61,
								'1577318400000': 6992500.53,
								'1577404800000': 6803087.01,
								'1577491200000': 6517985.71,
								'1577577600000': 7137199.779999999,
								'1577664000000': 7079892.09,
								'1577750400000': 6602415.58,
								'1577836800000': 1962086.98,
								'1577923200000': 6617005.11,
								'1578009600000': 6414573.59,
								'1578096000000': 7063264.08,
								'1578182400000': 7639199.95,
								'1578268800000': 6183244.24,
								'1578355200000': 5273736.24,
								'1578441600000': 5092378.37,
								'1578528000000': 5491232.4,
								'1578614400000': 6119030.17,
								'1578700800000': 7288230.63,
								'1578787200000': 7637408.109999999,
								'1578873600000': 6168173.74,
								'1578960000000': 5512541.98,
								'1579046400000': 5949904.54,
								'1579132800000': 6472542.83,
								'1579219200000': 7191846.5600000005,
								'1579305600000': 8279989.98,
								'1579392000000': 7677978.26
							}
						},
						{
							name: 'origin2',
							values: {
								'1576886400000': 8694056.6,
								'1576972800000': 4520089.8,
								'1577059200000': 9249089,
								'1577145600000': 11687537.6,
								'1577232000000': 7893941,
								'1577318400000': 12667316.2,
								'1577404800000': 9582520.7,
								'1577491200000': 7599686,
								'1577577600000': 6977969.15,
								'1577664000000': 5780981.4,
								'1577750400000': 6731535.5,
								'1577836800000': 6780070.7,
								'1577923200000': 5722313,
								'1578009600000': 4113330.8,
								'1578096000000': 3709821.4,
								'1578182400000': 4554321.8,
								'1578268800000': 5779013.6,
								'1578355200000': 4762377.8,
								'1578441600000': 3219677.3,
								'1578528000000': 3647282.4,
								'1578614400000': 4040135.7,
								'1578700800000': 4974446.5,
								'1578787200000': 5573922.4,
								'1578873600000': 4494917.9,
								'1578960000000': 3288181.7,
								'1579046400000': 3457594.2,
								'1579132800000': 3653061,
								'1579219200000': 2560114.4,
								'1579305600000': 130976.8,
								'1579392000000': 69790.1
							}
						},
						{
							name: 'origin3',
							values: {
								'1576886400000': 17252408.71,
								'1576972800000': 18756363.95,
								'1577059200000': 12508939.67,
								'1577145600000': 11052750.89,
								'1577232000000': 7493463.13,
								'1577318400000': 13387027.34,
								'1577404800000': 16192528.43,
								'1577491200000': 17618946.87,
								'1577577600000': 18173871.86,
								'1577664000000': 14357439.31,
								'1577750400000': 12437682.9,
								'1577836800000': 7454074.95,
								'1577923200000': 14204181.48,
								'1578009600000': 15250471.27,
								'1578096000000': 15095457,
								'1578182400000': 17612988.74,
								'1578268800000': 12943699.38,
								'1578355200000': 12012232.7,
								'1578441600000': 12255835.11,
								'1578528000000': 12221058.57,
								'1578614400000': 13646304.15,
								'1578700800000': 15352247.49,
								'1578787200000': 16732936.33,
								'1578873600000': 12095548.96,
								'1578960000000': 10358957.08,
								'1579046400000': 10654979.99,
								'1579132800000': 12490257.31,
								'1579219200000': 13205315.19,
								'1579305600000': 13968785.12,
								'1579392000000': 12512136.7
							}
						},
						{
							name: 'origin4',
							values: {
								'1576886400000': 16071.8,
								'1576972800000': 13798,
								'1577059200000': 12563.4,
								'1577145600000': 11131.3,
								'1577232000000': 1723.6,
								'1577318400000': 12340.2,
								'1577404800000': 13978.1,
								'1577491200000': 13245.1,
								'1577577600000': 10129.6,
								'1577664000000': 13068.8,
								'1577750400000': 6459.3,
								'1577836800000': 1013.7,
								'1577923200000': 8979.1,
								'1578009600000': 14546.1,
								'1578096000000': 13346,
								'1578182400000': 14088,
								'1578268800000': 10836,
								'1578355200000': 7949.5,
								'1578441600000': 9657.9,
								'1578528000000': 9723.6,
								'1578614400000': 11393.6,
								'1578700800000': 12291.7,
								'1578787200000': 12677.2,
								'1578873600000': 10470.2,
								'1578960000000': 7556,
								'1579046400000': 8684.4,
								'1579132800000': 8063.9,
								'1579219200000': 10073.3,
								'1579305600000': 9826.8,
								'1579392000000': 7023.8
							}
						},
						{
							name: 'origin5',
							values: {
								'1576886400000': 509940.4,
								'1576972800000': 496081.6,
								'1577059200000': 443546.4,
								'1577145600000': 378094.5,
								'1577232000000': 264048.7,
								'1577318400000': 517926.1,
								'1577404800000': 479600.4,
								'1577491200000': 449708.4,
								'1577577600000': 465477.2,
								'1577664000000': 446835.6,
								'1577750400000': 361595.3,
								'1577836800000': 238491.2,
								'1577923200000': 453618.9,
								'1578009600000': 405542.6,
								'1578096000000': 420029.5,
								'1578182400000': 480542.9,
								'1578268800000': 392368.2,
								'1578355200000': 310231.5,
								'1578441600000': 303377.6,
								'1578528000000': 350088.5,
								'1578614400000': 399812.6,
								'1578700800000': 449571.7,
								'1578787200000': 497927.7,
								'1578873600000': 410032.3,
								'1578960000000': 340789.5,
								'1579046400000': 358785.8,
								'1579132800000': 386427,
								'1579219200000': 430178.4,
								'1579305600000': 471585.4,
								'1579392000000': 368434.5
							}
						}
					],
					total: 2928900449.51,
					trend: -0.09,
					uuid: '0ab774e9-ab85-4ee7-96e5-2c705b21c985'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_YEAR,
				{
					values: [
						{
							name: 'origin5',
							values: {
								'1547424000000': 235883.8,
								'1548028800000': 1422493.6,
								'1548633600000': 1563921.2,
								'1549238400000': 1790915.2,
								'1549843200000': 1811843.42,
								'1550448000000': 1980004.5,
								'1551052800000': 2083808,
								'1551657600000': 2466101.6,
								'1552262400000': 2306537.8,
								'1552867200000': 2632564.5,
								'1553472000000': 3148621.04,
								'1554076800000': 2687627.6,
								'1554681600000': 2476404.1,
								'1555286400000': 2206136.7,
								'1555891200000': 2305091.6,
								'1556496000000': 2517742,
								'1557100800000': 2272802.3,
								'1557705600000': 2293758.12,
								'1558310400000': 2337192.62,
								'1558915200000': 2462371.7,
								'1559520000000': 2413760.7,
								'1560124800000': 2440423.5,
								'1560729600000': 2537669.8,
								'1561334400000': 2964656.6,
								'1561939200000': 3234339.4,
								'1562544000000': 3207749.1,
								'1563148800000': 3126340.5,
								'1563753600000': 2904099.3,
								'1564358400000': 3051628.2,
								'1564963200000': 2781513.8,
								'1565568000000': 2664316.27,
								'1566172800000': 2648821.08,
								'1566777600000': 2515540.68,
								'1567382400000': 2763708,
								'1567987200000': 2886672.3,
								'1568592000000': 2961311.4,
								'1569196800000': 3275081.79,
								'1569801600000': 2931303.6,
								'1570406400000': 2824180.35,
								'1571011200000': 2804306.9,
								'1571616000000': 2809864.6,
								'1572220800000': 2812278.5,
								'1572825600000': 2789982.2,
								'1573430400000': 2861885.75,
								'1574035200000': 2662477.3,
								'1574640000000': 3025679.55,
								'1575244800000': 2778244.8,
								'1575849600000': 3041100.7,
								'1576454400000': 3179978.4,
								'1577059200000': 3022023.8,
								'1577664000000': 2828497.2,
								'1578268800000': 2720369.5,
								'1578873600000': 2777596.4
							}
						},
						{
							name: 'origin0',
							values: {
								'1547424000000': 9499449.9,
								'1548028800000': 38281685.9,
								'1548633600000': 43625320.6,
								'1549238400000': 34908208.3,
								'1549843200000': 41609223.6,
								'1550448000000': 44122211.25,
								'1551052800000': 34517118.6,
								'1551657600000': 30045196.6,
								'1552262400000': 37319636.1,
								'1552867200000': 39192359.5,
								'1553472000000': 48444860.45,
								'1554076800000': 40011836,
								'1554681600000': 31476481,
								'1555286400000': 3855145.9,
								'1555891200000': 129678,
								'1556496000000': 53570.65,
								'1557100800000': 39274.7,
								'1557705600000': 43947.6,
								'1558310400000': 48934.3,
								'1558915200000': 49643.1,
								'1559520000000': 50574.7,
								'1560124800000': 45017.4,
								'1560729600000': 49549.65,
								'1561334400000': 39631.75,
								'1561939200000': 42950.5,
								'1562544000000': 43263.35,
								'1563148800000': 40755.75,
								'1563753600000': 35944.1,
								'1564358400000': 44078.9,
								'1564963200000': 39851.3,
								'1565568000000': 35883.4,
								'1566172800000': 53468.2,
								'1566777600000': 48386.9,
								'1567382400000': 52952.7,
								'1567987200000': 45074,
								'1568592000000': 46595.3,
								'1569196800000': 45836.1,
								'1569801600000': 46624.7,
								'1570406400000': 50197.35,
								'1571011200000': 54449.6,
								'1571616000000': 45449.2,
								'1572220800000': 43059,
								'1572825600000': 41774.8,
								'1573430400000': 46065.2,
								'1574035200000': 45660.7,
								'1574640000000': 55409.3,
								'1575244800000': 50985.8,
								'1575849600000': 55707,
								'1576454400000': 57813.2,
								'1577059200000': 51489.2,
								'1577664000000': 49659.8,
								'1578268800000': 57537.8,
								'1578873600000': 50334.9
							}
						},
						{
							name: 'origin1',
							values: {
								'1547424000000': 1480161.2,
								'1548028800000': 12296792.2,
								'1548633600000': 24691720.8,
								'1549238400000': 27457875.9,
								'1549843200000': 32327771.2,
								'1550448000000': 35462738.5,
								'1551052800000': 36785602.7,
								'1551657600000': 32611955.9,
								'1552262400000': 34994831.8,
								'1552867200000': 33078695.4,
								'1553472000000': 37350885,
								'1554076800000': 38059986.8,
								'1554681600000': 37169416.2,
								'1555286400000': 35534008.9,
								'1555891200000': 39153825.6,
								'1556496000000': 10403765.2,
								'1557100800000': 8778695.3,
								'1557705600000': 8899155.1,
								'1558310400000': 9533319.2,
								'1558915200000': 22055291.5,
								'1559520000000': 21372206.7,
								'1560124800000': 19158475.8,
								'1560729600000': 15636011.9,
								'1561334400000': 19351337.2,
								'1561939200000': 20940570.1,
								'1562544000000': 19101364.4,
								'1563148800000': 22986591.5,
								'1563753600000': 23319769.93,
								'1564358400000': 27760987.63,
								'1564963200000': 23424116.75,
								'1565568000000': 26656880.6,
								'1566172800000': 29288071.84,
								'1566777600000': 31787021.95,
								'1567382400000': 38244070.74,
								'1567987200000': 45733425.45,
								'1568592000000': 49352880.99,
								'1569196800000': 34738404.32,
								'1569801600000': 38214104.86,
								'1570406400000': 38824208.78,
								'1571011200000': 45068177.33,
								'1571616000000': 41899514.09,
								'1572220800000': 46777933.79,
								'1572825600000': 43851931.03,
								'1573430400000': 49960948.81,
								'1574035200000': 46049176.11,
								'1574640000000': 49810716.31,
								'1575244800000': 54050518.34,
								'1575849600000': 48753624.54,
								'1576454400000': 50968936.69,
								'1577059200000': 43403052.64,
								'1577664000000': 43378437.38,
								'1578268800000': 43085260.160000004,
								'1578873600000': 47252977.89
							}
						},
						{
							name: 'origin0 Call Center Atento',
							values: {
								'1547424000000': 0,
								'1548028800000': 0,
								'1548633600000': 0,
								'1549238400000': 0,
								'1549843200000': 0,
								'1550448000000': 0,
								'1551052800000': 0,
								'1551657600000': 0,
								'1552262400000': 0,
								'1552867200000': 0,
								'1553472000000': 0,
								'1554076800000': 0,
								'1554681600000': 0,
								'1555286400000': 87200,
								'1555891200000': 19255700,
								'1556496000000': 27013300,
								'1557100800000': 20682200,
								'1557705600000': 25499000,
								'1558310400000': 16645000,
								'1558915200000': 7876900,
								'1559520000000': 0,
								'1560124800000': 0,
								'1560729600000': 0,
								'1561334400000': 0,
								'1561939200000': 0,
								'1562544000000': 0,
								'1563148800000': 0,
								'1563753600000': 0,
								'1564358400000': 0,
								'1564963200000': 0,
								'1565568000000': 0,
								'1566172800000': 0,
								'1566777600000': 0,
								'1567382400000': 0,
								'1567987200000': 0,
								'1568592000000': 0,
								'1569196800000': 0,
								'1569801600000': 0,
								'1570406400000': 0,
								'1571011200000': 0,
								'1571616000000': 0,
								'1572220800000': 0,
								'1572825600000': 0,
								'1573430400000': 0,
								'1574035200000': 0,
								'1574640000000': 0,
								'1575244800000': 0,
								'1575849600000': 0,
								'1576454400000': 0,
								'1577059200000': 0,
								'1577664000000': 0,
								'1578268800000': 0,
								'1578873600000': 0
							}
						},
						{
							name: 'origin0 App',
							values: {
								'1547424000000': 0,
								'1548028800000': 0,
								'1548633600000': 0,
								'1549238400000': 0,
								'1549843200000': 0,
								'1550448000000': 0,
								'1551052800000': 0,
								'1551657600000': 0,
								'1552262400000': 0,
								'1552867200000': 0,
								'1553472000000': 0,
								'1554076800000': 0,
								'1554681600000': 0,
								'1555286400000': 0,
								'1555891200000': 6687100,
								'1556496000000': 10017300,
								'1557100800000': 8414400,
								'1557705600000': 9882400,
								'1558310400000': 10684100,
								'1558915200000': 7751200,
								'1559520000000': 22789963,
								'1560124800000': 8828000,
								'1560729600000': 0,
								'1561334400000': 0,
								'1561939200000': 0,
								'1562544000000': 0,
								'1563148800000': 0,
								'1563753600000': 0,
								'1564358400000': 0,
								'1564963200000': 0,
								'1565568000000': 0,
								'1566172800000': 0,
								'1566777600000': 0,
								'1567382400000': 0,
								'1567987200000': 0,
								'1568592000000': 0,
								'1569196800000': 0,
								'1569801600000': 0,
								'1570406400000': 0,
								'1571011200000': 0,
								'1571616000000': 0,
								'1572220800000': 0,
								'1572825600000': 0,
								'1573430400000': 0,
								'1574035200000': 0,
								'1574640000000': 0,
								'1575244800000': 0,
								'1575849600000': 0,
								'1576454400000': 0,
								'1577059200000': 0,
								'1577664000000': 0,
								'1578268800000': 0,
								'1578873600000': 0
							}
						},
						{
							name: 'origin0 Web',
							values: {
								'1547424000000': 0,
								'1548028800000': 0,
								'1548633600000': 0,
								'1549238400000': 0,
								'1549843200000': 0,
								'1550448000000': 0,
								'1551052800000': 0,
								'1551657600000': 0,
								'1552262400000': 0,
								'1552867200000': 0,
								'1553472000000': 0,
								'1554076800000': 0,
								'1554681600000': 0,
								'1555286400000': 0,
								'1555891200000': 2427000,
								'1556496000000': 6108800,
								'1557100800000': 4253700,
								'1557705600000': 5325400,
								'1558310400000': 6234200,
								'1558915200000': 5062200,
								'1559520000000': 13198663,
								'1560124800000': 5141000,
								'1560729600000': 0,
								'1561334400000': 0,
								'1561939200000': 0,
								'1562544000000': 0,
								'1563148800000': 0,
								'1563753600000': 0,
								'1564358400000': 0,
								'1564963200000': 0,
								'1565568000000': 0,
								'1566172800000': 0,
								'1566777600000': 0,
								'1567382400000': 0,
								'1567987200000': 0,
								'1568592000000': 0,
								'1569196800000': 0,
								'1569801600000': 0,
								'1570406400000': 0,
								'1571011200000': 0,
								'1571616000000': 0,
								'1572220800000': 0,
								'1572825600000': 0,
								'1573430400000': 0,
								'1574035200000': 0,
								'1574640000000': 0,
								'1575244800000': 0,
								'1575849600000': 0,
								'1576454400000': 0,
								'1577059200000': 0,
								'1577664000000': 0,
								'1578268800000': 0,
								'1578873600000': 0
							}
						}
					],
					total: 35299607719.1,
					trend: 8.61,
					uuid: '0ab774e9-ab85-4ee7-96e5-2c705b21c985'
				}
			]
		])
	],
	[
		'7-GROUP_BY_FLEET',
		new Map([
			[
				TIME_FILTER_OPTION.LAST_24H,
				{
					values: [
						{
							name: 'origin5',
							values: {
								'1579381200000': 33901.9,
								'1579384800000': 51048.1,
								'1579388400000': 71144,
								'1579392000000': 74171.6,
								'1579395600000': 48481.5,
								'1579399200000': 31093.4,
								'1579402800000': 12337.6,
								'1579406400000': 5876.1,
								'1579410000000': 848.4,
								'1579413600000': 46.2,
								'1579428000000': 34.2,
								'1579435200000': 328.6,
								'1579438800000': 711.5,
								'1579442400000': 4726.7,
								'1579446000000': 18360.6,
								'1579449600000': 25336.1,
								'1579453200000': 28506,
								'1579456800000': 24451.5,
								'1579460400000': 25227.3,
								'1579464000000': 30128,
								'1579467600000': 36992.3,
								'1579431600000': 0,
								'1579417200000': 0,
								'1579420800000': 0,
								'1579424400000': 0
							}
						},
						{
							name: 'origin0',
							values: {
								'1579381200000': 321.4,
								'1579384800000': 612.3,
								'1579388400000': 1750.1,
								'1579392000000': 1257.2,
								'1579395600000': 1038.5,
								'1579399200000': 243.1,
								'1579402800000': 25.8,
								'1579406400000': 0,
								'1579410000000': 0,
								'1579413600000': 0,
								'1579428000000': 87.2,
								'1579435200000': 97,
								'1579438800000': 0,
								'1579442400000': 79.8,
								'1579446000000': 103.6,
								'1579449600000': 311.1,
								'1579453200000': 390.6,
								'1579456800000': 587.1,
								'1579460400000': 186.7,
								'1579464000000': 184.8,
								'1579467600000': 436.6,
								'1579431600000': 69.7,
								'1579417200000': 0,
								'1579420800000': 0,
								'1579424400000': 0
							}
						},
						{
							name: 'origin1',
							values: {
								'1579381200000': 333953.13,
								'1579384800000': 327200.89,
								'1579388400000': 302770.52,
								'1579392000000': 567723.28,
								'1579395600000': 652047.86,
								'1579399200000': 974149.86,
								'1579402800000': 700756.55,
								'1579406400000': 724886.5,
								'1579410000000': 520064.9,
								'1579413600000': 264767.92,
								'1579428000000': 4292.38,
								'1579435200000': 8196.99,
								'1579438800000': 34444.71,
								'1579442400000': 115538.32,
								'1579446000000': 231034.08,
								'1579449600000': 325178.73,
								'1579453200000': 351427.64,
								'1579456800000': 383556.48,
								'1579460400000': 527755.7,
								'1579464000000': 507422.16,
								'1579467600000': 515495.53,
								'1579431600000': 6588.81,
								'1579417200000': 170793.05,
								'1579420800000': 87077.79,
								'1579424400000': 5834.79
							}
						}
					],
					total: 147146581.33,
					trend: 0.22,
					uuid: '0ab774e9-ab85-4ee7-96e5-2c705b21c985'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_7D,
				{
					values: [
						{
							name: 'origin5',
							values: {
								'1578873600000': 412689.8,
								'1578960000000': 342418,
								'1579046400000': 359751.8,
								'1579132800000': 387123.2,
								'1579219200000': 431816.4,
								'1579305600000': 473552.9,
								'1579392000000': 370244.3
							}
						},
						{
							name: 'origin0',
							values: {
								'1578873600000': 7812.7,
								'1578960000000': 5927.5,
								'1579046400000': 7718.4,
								'1579132800000': 7367.7,
								'1579219200000': 8435.3,
								'1579305600000': 7859.3,
								'1579392000000': 5214
							}
						},
						{
							name: 'origin1',
							values: {
								'1578873600000': 6168173.74,
								'1578960000000': 5512541.98,
								'1579046400000': 5949904.54,
								'1579132800000': 6472542.83,
								'1579219200000': 7191846.56,
								'1579305600000': 8279989.98,
								'1579392000000': 7677978.26
							}
						}
					],
					total: 657599543.28,
					trend: -0.05,
					uuid: '0ab774e9-ab85-4ee7-96e5-2c705b21c985'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_MONTH,
				{
					values: [
						{
							name: 'origin5',
							values: {
								'1576886400000': 514714.3,
								'1576972800000': 500355.8,
								'1577059200000': 446326.7,
								'1577145600000': 381546.6,
								'1577232000000': 265426.4,
								'1577318400000': 523639.4,
								'1577404800000': 483154.7,
								'1577491200000': 452978.3,
								'1577577600000': 468951.7,
								'1577664000000': 450131,
								'1577750400000': 364268.8,
								'1577836800000': 239504.9,
								'1577923200000': 457095.8,
								'1578009600000': 409841.6,
								'1578096000000': 423080.1,
								'1578182400000': 484575,
								'1578268800000': 395045.9,
								'1578355200000': 312087.6,
								'1578441600000': 305369.9,
								'1578528000000': 352049.4,
								'1578614400000': 402231.6,
								'1578700800000': 452260.4,
								'1578787200000': 501324.7,
								'1578873600000': 412689.8,
								'1578960000000': 342418,
								'1579046400000': 359751.8,
								'1579132800000': 387123.2,
								'1579219200000': 431816.4,
								'1579305600000': 473552.9,
								'1579392000000': 370244.3
							}
						},
						{
							name: 'origin0',
							values: {
								'1576886400000': 11297.9,
								'1576972800000': 9523.8,
								'1577059200000': 9783.1,
								'1577145600000': 7679.2,
								'1577232000000': 345.9,
								'1577318400000': 6626.9,
								'1577404800000': 10423.8,
								'1577491200000': 9975.2,
								'1577577600000': 6655.1,
								'1577664000000': 9773.4,
								'1577750400000': 3785.8,
								'1577836800000': 0,
								'1577923200000': 5502.2,
								'1578009600000': 10247.1,
								'1578096000000': 10295.4,
								'1578182400000': 10055.9,
								'1578268800000': 8158.3,
								'1578355200000': 6093.4,
								'1578441600000': 7665.6,
								'1578528000000': 7762.7,
								'1578614400000': 8974.6,
								'1578700800000': 9603,
								'1578787200000': 9280.2,
								'1578873600000': 7812.7,
								'1578960000000': 5927.5,
								'1579046400000': 7718.4,
								'1579132800000': 7367.7,
								'1579219200000': 8435.3,
								'1579305600000': 7859.3,
								'1579392000000': 5214
							}
						},
						{
							name: 'origin1',
							values: {
								'1576886400000': 8726165.1,
								'1576972800000': 7300145.84,
								'1577059200000': 6881183.45,
								'1577145600000': 6891879.55,
								'1577232000000': 2179216.61,
								'1577318400000': 6992500.53,
								'1577404800000': 6803087.01,
								'1577491200000': 6517985.71,
								'1577577600000': 7137199.78,
								'1577664000000': 7079892.09,
								'1577750400000': 6602415.58,
								'1577836800000': 1962086.98,
								'1577923200000': 6617005.11,
								'1578009600000': 6414573.59,
								'1578096000000': 7063264.08,
								'1578182400000': 7639199.95,
								'1578268800000': 6183244.24,
								'1578355200000': 5273736.24,
								'1578441600000': 5092378.37,
								'1578528000000': 5491232.4,
								'1578614400000': 6119030.17,
								'1578700800000': 7288230.63,
								'1578787200000': 7637408.11,
								'1578873600000': 6168173.74,
								'1578960000000': 5512541.98,
								'1579046400000': 5949904.54,
								'1579132800000': 6472542.83,
								'1579219200000': 7191846.56,
								'1579305600000': 8279989.98,
								'1579392000000': 7677978.26
							}
						}
					],
					total: 2928900449.51,
					trend: -0.09,
					uuid: '0ab774e9-ab85-4ee7-96e5-2c705b21c985'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_YEAR,
				{
					values: [
						{
							name: 'origin5',
							values: {
								'1547424000000': 235883.8,
								'1548028800000': 1422493.6,
								'1548633600000': 1563921.2,
								'1549238400000': 1790915.2,
								'1549843200000': 1811843.42,
								'1550448000000': 1980004.5,
								'1551052800000': 2083808,
								'1551657600000': 2466101.6,
								'1552262400000': 2306537.8,
								'1552867200000': 2632564.5,
								'1553472000000': 3148621.04,
								'1554076800000': 2687627.6,
								'1554681600000': 2476404.1,
								'1555286400000': 2206136.7,
								'1555891200000': 2305091.6,
								'1556496000000': 2517742,
								'1557100800000': 2272802.3,
								'1557705600000': 2293758.12,
								'1558310400000': 2337192.62,
								'1558915200000': 2462371.7,
								'1559520000000': 2413760.7,
								'1560124800000': 2440423.5,
								'1560729600000': 2537669.8,
								'1561334400000': 2964656.6,
								'1561939200000': 3234339.4,
								'1562544000000': 3207749.1,
								'1563148800000': 3126340.5,
								'1563753600000': 2904099.3,
								'1564358400000': 3051628.2,
								'1564963200000': 2781513.8,
								'1565568000000': 2664316.27,
								'1566172800000': 2648821.08,
								'1566777600000': 2515540.68,
								'1567382400000': 2763708,
								'1567987200000': 2886672.3,
								'1568592000000': 2961311.4,
								'1569196800000': 3275081.79,
								'1569801600000': 2931303.6,
								'1570406400000': 2824180.35,
								'1571011200000': 2804306.9,
								'1571616000000': 2809864.6,
								'1572220800000': 2812278.5,
								'1572825600000': 2789982.2,
								'1573430400000': 2861885.75,
								'1574035200000': 2662477.3,
								'1574640000000': 3025679.55,
								'1575244800000': 2778244.8,
								'1575849600000': 3041100.7,
								'1576454400000': 3179978.4,
								'1577059200000': 3022023.8,
								'1577664000000': 2828497.2,
								'1578268800000': 2720369.5,
								'1578873600000': 2777596.4
							}
						},
						{
							name: 'origin0',
							values: {
								'1547424000000': 9499449.9,
								'1548028800000': 38281685.9,
								'1548633600000': 43625320.6,
								'1549238400000': 34908208.3,
								'1549843200000': 41609223.6,
								'1550448000000': 44122211.25,
								'1551052800000': 34517118.6,
								'1551657600000': 30045196.6,
								'1552262400000': 37319636.1,
								'1552867200000': 39192359.5,
								'1553472000000': 48444860.45,
								'1554076800000': 40011836,
								'1554681600000': 31476481,
								'1555286400000': 3855145.9,
								'1555891200000': 129678,
								'1556496000000': 53570.65,
								'1557100800000': 39274.7,
								'1557705600000': 43947.6,
								'1558310400000': 48934.3,
								'1558915200000': 49643.1,
								'1559520000000': 50574.7,
								'1560124800000': 45017.4,
								'1560729600000': 49549.65,
								'1561334400000': 39631.75,
								'1561939200000': 42950.5,
								'1562544000000': 43263.35,
								'1563148800000': 40755.75,
								'1563753600000': 35944.1,
								'1564358400000': 44078.9,
								'1564963200000': 39851.3,
								'1565568000000': 35883.4,
								'1566172800000': 53468.2,
								'1566777600000': 48386.9,
								'1567382400000': 52952.7,
								'1567987200000': 45074,
								'1568592000000': 46595.3,
								'1569196800000': 45836.1,
								'1569801600000': 46624.7,
								'1570406400000': 50197.35,
								'1571011200000': 54449.6,
								'1571616000000': 45449.2,
								'1572220800000': 43059,
								'1572825600000': 41774.8,
								'1573430400000': 46065.2,
								'1574035200000': 45660.7,
								'1574640000000': 55409.3,
								'1575244800000': 50985.8,
								'1575849600000': 55707,
								'1576454400000': 57813.2,
								'1577059200000': 51489.2,
								'1577664000000': 49659.8,
								'1578268800000': 57537.8,
								'1578873600000': 50334.9
							}
						},
						{
							name: 'origin1',
							values: {
								'1547424000000': 1480161.2,
								'1548028800000': 12296792.2,
								'1548633600000': 24691720.8,
								'1549238400000': 27457875.9,
								'1549843200000': 32327771.2,
								'1550448000000': 35462738.5,
								'1551052800000': 36785602.7,
								'1551657600000': 32611955.9,
								'1552262400000': 34994831.8,
								'1552867200000': 33078695.4,
								'1553472000000': 37350885,
								'1554076800000': 38059986.8,
								'1554681600000': 37169416.2,
								'1555286400000': 35534008.9,
								'1555891200000': 39153825.6,
								'1556496000000': 10403765.2,
								'1557100800000': 8778695.3,
								'1557705600000': 8899155.1,
								'1558310400000': 9533319.2,
								'1558915200000': 22055291.5,
								'1559520000000': 21372206.7,
								'1560124800000': 19158475.8,
								'1560729600000': 15636011.9,
								'1561334400000': 19351337.2,
								'1561939200000': 20940570.1,
								'1562544000000': 19101364.4,
								'1563148800000': 22986591.5,
								'1563753600000': 23319769.93,
								'1564358400000': 27760987.63,
								'1564963200000': 23424116.75,
								'1565568000000': 26656880.6,
								'1566172800000': 29288071.84,
								'1566777600000': 31787021.95,
								'1567382400000': 38244070.74,
								'1567987200000': 45733425.45,
								'1568592000000': 49352880.99,
								'1569196800000': 34738404.32,
								'1569801600000': 38214104.86,
								'1570406400000': 38824208.78,
								'1571011200000': 45068177.33,
								'1571616000000': 41899514.09,
								'1572220800000': 46777933.79,
								'1572825600000': 43851931.03,
								'1573430400000': 49960948.81,
								'1574035200000': 46049176.11,
								'1574640000000': 49810716.31,
								'1575244800000': 54050518.34,
								'1575849600000': 48753624.54,
								'1576454400000': 50968936.69,
								'1577059200000': 43403052.64,
								'1577664000000': 43378437.38,
								'1578268800000': 43085260.160000004,
								'1578873600000': 47252977.89
							}
						},
						{
							name: 'origin0 Call Center Atento',
							values: {
								'1547424000000': 0,
								'1548028800000': 0,
								'1548633600000': 0,
								'1549238400000': 0,
								'1549843200000': 0,
								'1550448000000': 0,
								'1551052800000': 0,
								'1551657600000': 0,
								'1552262400000': 0,
								'1552867200000': 0,
								'1553472000000': 0,
								'1554076800000': 0,
								'1554681600000': 0,
								'1555286400000': 87200,
								'1555891200000': 19255700,
								'1556496000000': 27013300,
								'1557100800000': 20682200,
								'1557705600000': 25499000,
								'1558310400000': 16645000,
								'1558915200000': 7876900,
								'1559520000000': 0,
								'1560124800000': 0,
								'1560729600000': 0,
								'1561334400000': 0,
								'1561939200000': 0,
								'1562544000000': 0,
								'1563148800000': 0,
								'1563753600000': 0,
								'1564358400000': 0,
								'1564963200000': 0,
								'1565568000000': 0,
								'1566172800000': 0,
								'1566777600000': 0,
								'1567382400000': 0,
								'1567987200000': 0,
								'1568592000000': 0,
								'1569196800000': 0,
								'1569801600000': 0,
								'1570406400000': 0,
								'1571011200000': 0,
								'1571616000000': 0,
								'1572220800000': 0,
								'1572825600000': 0,
								'1573430400000': 0,
								'1574035200000': 0,
								'1574640000000': 0,
								'1575244800000': 0,
								'1575849600000': 0,
								'1576454400000': 0,
								'1577059200000': 0,
								'1577664000000': 0,
								'1578268800000': 0,
								'1578873600000': 0
							}
						},
						{
							name: 'origin0 App',
							values: {
								'1547424000000': 0,
								'1548028800000': 0,
								'1548633600000': 0,
								'1549238400000': 0,
								'1549843200000': 0,
								'1550448000000': 0,
								'1551052800000': 0,
								'1551657600000': 0,
								'1552262400000': 0,
								'1552867200000': 0,
								'1553472000000': 0,
								'1554076800000': 0,
								'1554681600000': 0,
								'1555286400000': 0,
								'1555891200000': 6687100,
								'1556496000000': 10017300,
								'1557100800000': 8414400,
								'1557705600000': 9882400,
								'1558310400000': 10684100,
								'1558915200000': 7751200,
								'1559520000000': 22789963,
								'1560124800000': 8828000,
								'1560729600000': 0,
								'1561334400000': 0,
								'1561939200000': 0,
								'1562544000000': 0,
								'1563148800000': 0,
								'1563753600000': 0,
								'1564358400000': 0,
								'1564963200000': 0,
								'1565568000000': 0,
								'1566172800000': 0,
								'1566777600000': 0,
								'1567382400000': 0,
								'1567987200000': 0,
								'1568592000000': 0,
								'1569196800000': 0,
								'1569801600000': 0,
								'1570406400000': 0,
								'1571011200000': 0,
								'1571616000000': 0,
								'1572220800000': 0,
								'1572825600000': 0,
								'1573430400000': 0,
								'1574035200000': 0,
								'1574640000000': 0,
								'1575244800000': 0,
								'1575849600000': 0,
								'1576454400000': 0,
								'1577059200000': 0,
								'1577664000000': 0,
								'1578268800000': 0,
								'1578873600000': 0
							}
						},
						{
							name: 'origin0 Web',
							values: {
								'1547424000000': 0,
								'1548028800000': 0,
								'1548633600000': 0,
								'1549238400000': 0,
								'1549843200000': 0,
								'1550448000000': 0,
								'1551052800000': 0,
								'1551657600000': 0,
								'1552262400000': 0,
								'1552867200000': 0,
								'1553472000000': 0,
								'1554076800000': 0,
								'1554681600000': 0,
								'1555286400000': 0,
								'1555891200000': 2427000,
								'1556496000000': 6108800,
								'1557100800000': 4253700,
								'1557705600000': 5325400,
								'1558310400000': 6234200,
								'1558915200000': 5062200,
								'1559520000000': 13198663,
								'1560124800000': 5141000,
								'1560729600000': 0,
								'1561334400000': 0,
								'1561939200000': 0,
								'1562544000000': 0,
								'1563148800000': 0,
								'1563753600000': 0,
								'1564358400000': 0,
								'1564963200000': 0,
								'1565568000000': 0,
								'1566172800000': 0,
								'1566777600000': 0,
								'1567382400000': 0,
								'1567987200000': 0,
								'1568592000000': 0,
								'1569196800000': 0,
								'1569801600000': 0,
								'1570406400000': 0,
								'1571011200000': 0,
								'1571616000000': 0,
								'1572220800000': 0,
								'1572825600000': 0,
								'1573430400000': 0,
								'1574035200000': 0,
								'1574640000000': 0,
								'1575244800000': 0,
								'1575849600000': 0,
								'1576454400000': 0,
								'1577059200000': 0,
								'1577664000000': 0,
								'1578268800000': 0,
								'1578873600000': 0
							}
						}
					],
					total: 35299607719.1,
					trend: 8.61,
					uuid: '0ab774e9-ab85-4ee7-96e5-2c705b21c985'
				}
			]
		])
	],
	[
		'8',
		new Map([
			[
				TIME_FILTER_OPTION.LAST_24H,
				{
					values: [
						{
							name: '',
							values: {
								'1579384800000': 6856709.32,
								'1579388400000': 8216505.81,
								'1579392000000': 14667058.69,
								'1579395600000': 15749801.16,
								'1579399200000': 13000723.34,
								'1579402800000': 8914068.48,
								'1579406400000': 5097510.93,
								'1579410000000': 1744136.9,
								'1579413600000': 612838.41,
								'1579417200000': 309296.14,
								'1579420800000': 93259.58,
								'1579424400000': 11124.78,
								'1579428000000': 10255.32,
								'1579431600000': 135040.5,
								'1579435200000': 680044.74,
								'1579438800000': 2440716.86,
								'1579442400000': 2800886.31,
								'1579446000000': 4926956.47,
								'1579449600000': 4919519.67,
								'1579453200000': 8400334.21,
								'1579456800000': 11421313.73,
								'1579460400000': 12135195.7,
								'1579464000000': 9401961.02,
								'1579467600000': 8062728.37,
								'1579471200000': 3937904.36
							}
						}
					],
					total: 144545890.8,
					trend: 0.18,
					uuid: 'a295897c-8a08-4e6f-b02a-9b18670c7342'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_7D,
				{
					values: [
						{
							name: '',
							values: {
								'1578873600000': 87494038.67,
								'1578960000000': 71337909.85,
								'1579046400000': 74727099.2,
								'1579132800000': 87399660.43,
								'1579219200000': 93065609.77,
								'1579305600000': 118848938.18,
								'1579392000000': 129660429.11
							}
						}
					],
					total: 662533685.21,
					trend: -0.04,
					uuid: 'a295897c-8a08-4e6f-b02a-9b18670c7342'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_MONTH,
				{
					values: [
						{
							name: '',
							values: {
								'1576886400000': 129765013.72,
								'1576972800000': 133525628.9,
								'1577059200000': 98306098.45,
								'1577145600000': 87345198.7,
								'1577232000000': 62076769.45,
								'1577318400000': 99450748.24,
								'1577404800000': 88075369.96,
								'1577491200000': 98193028.9,
								'1577577600000': 106815680.26,
								'1577664000000': 92218152.39,
								'1577750400000': 88542980.61,
								'1577836800000': 95774551.85,
								'1577923200000': 109615374.13,
								'1578009600000': 90811555.1,
								'1578096000000': 90172065.89,
								'1578182400000': 107512107.35,
								'1578268800000': 105364770.44,
								'1578355200000': 80543367.76,
								'1578441600000': 73157045.4,
								'1578528000000': 82432485.69,
								'1578614400000': 92548588.18,
								'1578700800000': 118522513.05,
								'1578787200000': 140531811.78,
								'1578873600000': 87494038.67,
								'1578960000000': 71337909.85,
								'1579046400000': 74727099.2,
								'1579132800000': 87399660.43,
								'1579219200000': 93065609.77,
								'1579305600000': 118848938.18,
								'1579392000000': 129660429.11
							}
						}
					],
					total: 2933834591.44,
					trend: -0.09,
					uuid: 'a295897c-8a08-4e6f-b02a-9b18670c7342'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_YEAR,
				{
					values: [
						{
							name: '',
							values: {
								'1547424000000': 92574719.6,
								'1548028800000': 433092670.4,
								'1548633600000': 483706164.7,
								'1549238400000': 475167486.35,
								'1549843200000': 464297104.42,
								'1550448000000': 515858470.35,
								'1551052800000': 524801653,
								'1551657600000': 504361637.9,
								'1552262400000': 533949716.5,
								'1552867200000': 541023066.3,
								'1553472000000': 654308185.49,
								'1554076800000': 563983979.17,
								'1554681600000': 586148587.36,
								'1555286400000': 574342480.39,
								'1555891200000': 571330356.19,
								'1556496000000': 751097637.11,
								'1557100800000': 594721887.88,
								'1557705600000': 599186557.58,
								'1558310400000': 573218469.21,
								'1558915200000': 658530520.72,
								'1559520000000': 649619091.46,
								'1560124800000': 595732622.36,
								'1560729600000': 672971367.14,
								'1561334400000': 763343574.34,
								'1561939200000': 682749502.27,
								'1562544000000': 623650011.37,
								'1563148800000': 663180808.03,
								'1563753600000': 668818980.54,
								'1564358400000': 778027379.33,
								'1564963200000': 765653298.81,
								'1565568000000': 728434419.59,
								'1566172800000': 796111787.42,
								'1566777600000': 836698145.42,
								'1567382400000': 788999677.75,
								'1567987200000': 800325279.14,
								'1568592000000': 829532211.33,
								'1569196800000': 927057531.62,
								'1569801600000': 734732069.44,
								'1570406400000': 748218244.65,
								'1571011200000': 798124171.9,
								'1571616000000': 810027108.24,
								'1572220800000': 817440656.62,
								'1572825600000': 822705345.26,
								'1573430400000': 859906896.26,
								'1574035200000': 758216611.31,
								'1574640000000': 766468227.3,
								'1575244800000': 727263367.99,
								'1575849600000': 768908390.86,
								'1576454400000': 755379783.83,
								'1577059200000': 640262893.97,
								'1577664000000': 674646787.33,
								'1578268800000': 693100582.31,
								'1578873600000': 663014915.75
							}
						}
					],
					total: 35305023091.57,
					trend: 8.61,
					uuid: 'a295897c-8a08-4e6f-b02a-9b18670c7342'
				}
			]
		])
	],
	[
		'8-GROUP_BY_MERCHANT',
		new Map([
			[
				TIME_FILTER_OPTION.LAST_24H,
				{
					values: [
						{
							name: 'merchant1',
							values: {
								'1579384800000': 100366,
								'1579388400000': 236057,
								'1579392000000': 681751,
								'1579395600000': 663546,
								'1579399200000': 403280,
								'1579402800000': 145053,
								'1579406400000': 63965,
								'1579410000000': 26834,
								'1579413600000': 12581,
								'1579417200000': 14772,
								'1579420800000': 13313,
								'1579424400000': 6632,
								'1579428000000': 6446,
								'1579431600000': 15068,
								'1579435200000': 27374,
								'1579438800000': 22725,
								'1579442400000': 51516,
								'1579446000000': 207009,
								'1579449600000': 429940,
								'1579453200000': 495174,
								'1579456800000': 376204,
								'1579460400000': 232949,
								'1579464000000': 154781,
								'1579467600000': 134238,
								'1579471200000': 59139
							}
						},
						{
							name: 'merchant3',
							values: {
								'1579384800000': 335555,
								'1579388400000': 266420,
								'1579392000000': 411070,
								'1579395600000': 511655,
								'1579399200000': 923480,
								'1579402800000': 686715,
								'1579406400000': 730405,
								'1579410000000': 513480,
								'1579413600000': 255845,
								'1579417200000': 156850,
								'1579420800000': 74220,
								'1579424400000': 0,
								'1579428000000': 0,
								'1579431600000': 0,
								'1579435200000': 4090,
								'1579438800000': 35720,
								'1579442400000': 113285,
								'1579446000000': 185900,
								'1579449600000': 253275,
								'1579453200000': 255785,
								'1579456800000': 355015,
								'1579460400000': 477635,
								'1579464000000': 494740,
								'1579467600000': 517910,
								'1579471200000': 178870
							}
						}
					],
					total: 144545890.8,
					trend: 0.18,
					uuid: 'a295897c-8a08-4e6f-b02a-9b18670c7342'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_7D,
				{
					values: [
						{
							name: 'merchant1',
							values: {
								'1578873600000': 4006807,
								'1578960000000': 3564076,
								'1579046400000': 3710728,
								'1579132800000': 3622397,
								'1579219200000': 4022021,
								'1579305600000': 4479647,
								'1579392000000': 4242352
							}
						},
						{
							name: 'merchant3',
							values: {
								'1578873600000': 5536010,
								'1578960000000': 4985545,
								'1579046400000': 5427770,
								'1579132800000': 6009075,
								'1579219200000': 6555430,
								'1579305600000': 7606435,
								'1579392000000': 7134370
							}
						}
					],
					total: 662533685.21,
					trend: -0.04,
					uuid: 'a295897c-8a08-4e6f-b02a-9b18670c7342'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_MONTH,
				{
					values: [
						{
							name: 'merchant1',
							values: {
								'1576886400000': 5828576,
								'1576972800000': 5280821,
								'1577059200000': 4460715,
								'1577145600000': 4083182,
								'1577232000000': 555377,
								'1577318400000': 3672958,
								'1577404800000': 4694993,
								'1577491200000': 5127303,
								'1577577600000': 5008166,
								'1577664000000': 4459113.01,
								'1577750400000': 3847455,
								'1577836800000': 948701,
								'1577923200000': 3639546,
								'1578009600000': 3760322,
								'1578096000000': 4202298,
								'1578182400000': 4466475,
								'1578268800000': 3867331,
								'1578355200000': 3109945,
								'1578441600000': 3449400,
								'1578528000000': 3693631,
								'1578614400000': 4000667,
								'1578700800000': 4518571,
								'1578787200000': 4815033,
								'1578873600000': 4006807,
								'1578960000000': 3564076,
								'1579046400000': 3710728,
								'1579132800000': 3622397,
								'1579219200000': 4022021,
								'1579305600000': 4479647,
								'1579392000000': 4242352
							}
						},
						{
							name: 'merchant3',
							values: {
								'1576886400000': 7639345,
								'1576972800000': 6316480,
								'1577059200000': 6268825,
								'1577145600000': 6317645,
								'1577232000000': 2359820,
								'1577318400000': 6707580,
								'1577404800000': 5962530,
								'1577491200000': 5540040,
								'1577577600000': 6171020,
								'1577664000000': 6421335,
								'1577750400000': 6215270,
								'1577836800000': 1954300,
								'1577923200000': 5914410,
								'1578009600000': 5724385,
								'1578096000000': 6268195,
								'1578182400000': 6839255,
								'1578268800000': 5537760,
								'1578355200000': 4733585,
								'1578441600000': 4416025,
								'1578528000000': 4948905,
								'1578614400000': 5389595,
								'1578700800000': 6354855,
								'1578787200000': 6683970,
								'1578873600000': 5536010,
								'1578960000000': 4985545,
								'1579046400000': 5427770,
								'1579132800000': 6009075,
								'1579219200000': 6555430,
								'1579305600000': 7606435,
								'1579392000000': 7134370
							}
						}
					],
					total: 2933834591.44,
					trend: -0.09,
					uuid: 'a295897c-8a08-4e6f-b02a-9b18670c7342'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_YEAR,
				{
					values: [
						{
							name: 'merchant1',
							values: {
								'1547424000000': 1086616.4,
								'1548028800000': 6578906.1,
								'1548633600000': 9019437.7,
								'1549238400000': 7844281.6,
								'1549843200000': 7904809.4,
								'1550448000000': 7583490.6,
								'1551052800000': 7296733.6,
								'1551657600000': 9646020.8,
								'1552262400000': 10149035.5,
								'1552867200000': 9980608.5,
								'1553472000000': 10293518,
								'1554076800000': 11207835,
								'1554681600000': 11671629.5,
								'1555286400000': 12627104.5,
								'1555891200000': 14739642,
								'1556496000000': 15151647,
								'1557100800000': 15643235.5,
								'1557705600000': 21910949.5,
								'1558310400000': 15746784.5,
								'1558915200000': 20684109,
								'1559520000000': 16473777,
								'1560124800000': 15740726.5,
								'1560729600000': 20047111,
								'1561334400000': 18712296.5,
								'1561939200000': 20866996,
								'1562544000000': 20218216,
								'1563148800000': 18024517,
								'1563753600000': 19595237.5,
								'1564358400000': 22959519,
								'1564963200000': 21329689,
								'1565568000000': 21512961,
								'1566172800000': 24392685,
								'1566777600000': 22687034,
								'1567382400000': 25485826.9,
								'1567987200000': 28142122.7,
								'1568592000000': 24783435.37,
								'1569196800000': 25415700,
								'1569801600000': 26699604.51,
								'1570406400000': 25139282.01,
								'1571011200000': 27418863.53,
								'1571616000000': 28996677.91,
								'1572220800000': 29548748.72,
								'1572825600000': 31476291.12,
								'1573430400000': 30379602.01,
								'1574035200000': 29435526,
								'1574640000000': 29004260.01,
								'1575244800000': 33258799.01,
								'1575849600000': 33690547.02,
								'1576454400000': 33885435.03,
								'1577059200000': 27602694.01,
								'1577664000000': 25323910.02,
								'1578268800000': 27454578.01,
								'1578873600000': 27650867
							}
						},
						{
							name: 'merchant3',
							values: {
								'1547424000000': 0,
								'1548028800000': 0,
								'1548633600000': 0,
								'1549238400000': 0,
								'1549843200000': 0,
								'1550448000000': 0,
								'1551052800000': 0,
								'1551657600000': 0,
								'1552262400000': 1423290,
								'1552867200000': 391685,
								'1553472000000': 928760,
								'1554076800000': 1303640,
								'1554681600000': 521145,
								'1555286400000': 571020,
								'1555891200000': 1595270,
								'1556496000000': 2388410,
								'1557100800000': 2380930,
								'1557705600000': 2514400,
								'1558310400000': 3182435,
								'1558915200000': 14731460,
								'1559520000000': 14855505,
								'1560124800000': 13133915,
								'1560729600000': 8268040,
								'1561334400000': 12569225,
								'1561939200000': 13360385,
								'1562544000000': 11716810,
								'1563148800000': 16797120,
								'1563753600000': 16639845,
								'1564358400000': 20581290,
								'1564963200000': 16537210,
								'1565568000000': 19950945,
								'1566172800000': 21823110,
								'1566777600000': 24808975,
								'1567382400000': 31069595,
								'1567987200000': 38284995,
								'1568592000000': 41694385,
								'1569196800000': 28100110,
								'1569801600000': 32064335,
								'1570406400000': 32539740,
								'1571011200000': 40747285,
								'1571616000000': 37408215,
								'1572220800000': 42140375,
								'1572825600000': 40030630,
								'1573430400000': 46047070,
								'1574035200000': 39692890,
								'1574640000000': 43713335,
								'1575244800000': 47895305,
								'1575849600000': 42885680,
								'1576454400000': 45628080,
								'1577059200000': 39327460,
								'1577664000000': 39337150,
								'1578268800000': 38064695,
								'1578873600000': 43275060
							}
						}
					],
					total: 35305023091.57,
					trend: 8.61,
					uuid: 'a295897c-8a08-4e6f-b02a-9b18670c7342'
				}
			]
		])
	],
	[
		'9-GROUP_BY_ORIGIN',
		new Map([
			[
				TIME_FILTER_OPTION.LAST_24H,
				{
					values: [
						{ name: 'origin0', total: 9536, values: { '1579384800000': 9536 } },
						{ name: 'origin7', total: 131, values: { '1579384800000': 131 } },
						{ name: 'origin2', total: 16326, values: { '1579384800000': 16326 } },
						{ name: 'origin9', total: 2183, values: { '1579384800000': 2183 } },
						{ name: 'origin8', total: 5010, values: { '1579384800000': 5010 } },
						{ name: 'origin1', total: 385, values: { '1579384800000': 385 } },
						{ name: 'origin10', total: 7612, values: { '1579384800000': 7612 } }
					],
					total: 41183,
					uuid: '15de20c1-4bba-4f85-b514-f9d90241ed72'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_7D,
				{
					values: [
						{ name: 'origin0', total: 51026, values: { '1578866400000': 51026 } },
						{ name: 'origin7', total: 823, values: { '1578866400000': 823 } },
						{ name: 'origin2', total: 85028, values: { '1578866400000': 85028 } },
						{ name: 'origin9', total: 39147, values: { '1578866400000': 39147 } },
						{ name: 'origin8', total: 28951, values: { '1578866400000': 28951 } },
						{ name: 'origin1', total: 1576, values: { '1578866400000': 1576 } },
						{ name: 'origin10', total: 46481, values: { '1578866400000': 46481 } }
					],
					total: 253032,
					uuid: '15de20c1-4bba-4f85-b514-f9d90241ed72'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_MONTH,
				{
					values: [
						{ name: 'origin0', total: 224629, values: { '1576879200000': 224629 } },
						{ name: 'origin7', total: 3541, values: { '1576879200000': 3541 } },
						{ name: 'origin2', total: 295957, values: { '1576879200000': 295957 } },
						{ name: 'origin9', total: 162832, values: { '1576879200000': 162832 } },
						{ name: 'origin8', total: 115287, values: { '1576879200000': 115287 } },
						{ name: 'origin1', total: 6538, values: { '1576879200000': 6538 } },
						{ name: 'origin10', total: 193391, values: { '1576879200000': 193391 } }
					],
					total: 1002175,
					uuid: '15de20c1-4bba-4f85-b514-f9d90241ed72'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_YEAR,
				{
					values: [
						{ name: 'origin0', total: 2479471, values: { '1547935200000': 2479471 } },
						{ name: 'origin7', total: 49665, values: { '1547935200000': 49665 } },
						{ name: 'origin2', total: 4568317, values: { '1547935200000': 4568317 } },
						{ name: 'origin9', total: 230993, values: { '1547935200000': 230993 } },
						{ name: 'origin8', total: 1356858, values: { '1547935200000': 1356858 } },
						{ name: 'origin1', total: 100842, values: { '1547935200000': 100842 } },
						{ name: 'origin10', total: 1440514, values: { '1547935200000': 1440514 } },
						{ name: 'origin7 App', total: 2721, values: { '1547935200000': 2721 } },
						{ name: 'origin7 Web', total: 1305, values: { '1547935200000': 1305 } },
						{ name: 'origin7 Call Center Atento', total: 3112, values: { '1547935200000': 3112 } }
					],
					total: 10233800,
					uuid: '15de20c1-4bba-4f85-b514-f9d90241ed72'
				}
			]
		])
	],
	[
		'9-GROUP_BY_FLEET',
		new Map([
			[
				TIME_FILTER_OPTION.LAST_24H,
				{
					values: [
						{ name: 'origin8', total: 5010, values: { '1579384800000': 5010 } },
						{ name: 'origin1', total: 379, values: { '1579384800000': 379 } },
						{ name: 'origin2', total: 16326, values: { '1579384800000': 16326 } },
						{ name: 'origin3', total: 2183, values: { '1579384800000': 2183 } },
						{ name: 'origin4', total: 7612, values: { '1579384800000': 7612 } },
						{ name: 'origin6', total: 174, values: { '1579384800000': 174 } },
						{ name: 'origin5 Urbanos', total: 6, values: { '1579384800000': 6 } },
						{ name: 'origin0', total: 9493, values: { '1579384800000': 9493 } }
					],
					total: 41183,
					uuid: '15de20c1-4bba-4f85-b514-f9d90241ed72'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_7D,
				{
					values: [
						{ name: 'origin8', total: 28951, values: { '1578866400000': 28951 } },
						{ name: 'origin1', total: 1335, values: { '1578866400000': 1335 } },
						{ name: 'origin2', total: 85028, values: { '1578866400000': 85028 } },
						{ name: 'origin3', total: 36653, values: { '1578866400000': 36653 } },
						{ name: 'origin4', total: 46481, values: { '1578866400000': 46481 } },
						{ name: 'origin3 Eats', total: 2494, values: { '1578866400000': 2494 } },
						{ name: 'origin6', total: 1032, values: { '1578866400000': 1032 } },
						{ name: 'origin5 Urbanos', total: 241, values: { '1578866400000': 241 } },
						{ name: 'origin0', total: 50817, values: { '1578866400000': 50817 } }
					],
					total: 253032,
					uuid: '15de20c1-4bba-4f85-b514-f9d90241ed72'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_MONTH,
				{
					values: [
						{ name: 'origin8', total: 115287, values: { '1576879200000': 115287 } },
						{ name: 'origin1', total: 2249, values: { '1576879200000': 2249 } },
						{ name: 'origin2', total: 295957, values: { '1576879200000': 295957 } },
						{ name: 'origin3', total: 144552, values: { '1576879200000': 144552 } },
						{ name: 'origin4', total: 193391, values: { '1576879200000': 193391 } },
						{ name: 'origin3 Eats', total: 18280, values: { '1576879200000': 18280 } },
						{ name: 'origin6', total: 5024, values: { '1576879200000': 5024 } },
						{ name: 'origin5 Urbanos', total: 4289, values: { '1576879200000': 4289 } },
						{ name: 'origin0', total: 223146, values: { '1576879200000': 223146 } }
					],
					total: 1002175,
					uuid: '15de20c1-4bba-4f85-b514-f9d90241ed72'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_YEAR,
				{
					values: [
						{ name: 'origin8', total: 1356858, values: { '1547935200000': 1356858 } },
						{ name: 'origin1', total: 2249, values: { '1547935200000': 2249 } },
						{ name: 'origin2', total: 4563619, values: { '1547935200000': 4563619 } },
						{ name: 'origin3', total: 210839, values: { '1547935200000': 210839 } },
						{ name: 'origin4', total: 1440511, values: { '1547935200000': 1440511 } },
						{ name: 'origin3 Eats', total: 20154, values: { '1547935200000': 20154 } },
						{ name: 'origin6', total: 239606, values: { '1547935200000': 239606 } },
						{ name: 'origin5 Urbanos', total: 119119, values: { '1547935200000': 119119 } },
						{ name: 'origin0', total: 2276135, values: { '1547935200000': 2276135 } }
					],
					total: 10233800,
					uuid: '15de20c1-4bba-4f85-b514-f9d90241ed72'
				}
			]
		])
	],
	[
		'10',
		new Map([
			[
				TIME_FILTER_OPTION.LAST_24H,
				{
					values: [
						{
							name: '',
							values: {
								'1579388400000': 0.05,
								'1579392000000': 0.05,
								'1579395600000': 0.05,
								'1579399200000': 0.06,
								'1579402800000': 0.06,
								'1579406400000': 0.08,
								'1579410000000': 0.09,
								'1579413600000': 0.07,
								'1579417200000': 0.09,
								'1579420800000': 0.03,
								'1579424400000': 0.12,
								'1579428000000': 0.08,
								'1579431600000': 0.14,
								'1579435200000': 0.07,
								'1579438800000': 0.04,
								'1579442400000': 0.04,
								'1579446000000': 0.04,
								'1579449600000': 0.03,
								'1579453200000': 0.04,
								'1579456800000': 0.04,
								'1579460400000': 0.05,
								'1579464000000': 0.05,
								'1579467600000': 0.05,
								'1579471200000': 0.04,
								'1579474800000': 0.06
							}
						}
					],
					total: 0.05,
					trend: 0.25,
					uuid: '1488d41a-646f-4d95-86a1-319e633e893d'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_7D,
				{
					values: [
						{
							name: '',
							values: {
								'1578873600000': 0.04,
								'1578960000000': 0.04,
								'1579046400000': 0.04,
								'1579132800000': 0.04,
								'1579219200000': 0.05,
								'1579305600000': 0.04,
								'1579392000000': 0.05
							}
						}
					],
					total: 0.05,
					trend: 0.25,
					uuid: '1488d41a-646f-4d95-86a1-319e633e893d'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_MONTH,
				{
					values: [
						{
							name: '',
							values: {
								'1576886400000': 0.05,
								'1576972800000': 0.05,
								'1577059200000': 0.06,
								'1577145600000': 0.08,
								'1577232000000': 0.15,
								'1577318400000': 0.06,
								'1577404800000': 0.06,
								'1577491200000': 0.05,
								'1577577600000': 0.05,
								'1577664000000': 0.05,
								'1577750400000': 0.07,
								'1577836800000': 0.08,
								'1577923200000': 0.06,
								'1578009600000': 0.04,
								'1578096000000': 0.04,
								'1578182400000': 0.04,
								'1578268800000': 0.04,
								'1578355200000': 0.04,
								'1578441600000': 0.05,
								'1578528000000': 0.04,
								'1578614400000': 0.04,
								'1578700800000': 0.04,
								'1578787200000': 0.04,
								'1578873600000': 0.04,
								'1578960000000': 0.04,
								'1579046400000': 0.04,
								'1579132800000': 0.04,
								'1579219200000': 0.05,
								'1579305600000': 0.04,
								'1579392000000': 0.05
							}
						}
					],
					total: 0.05,
					trend: -0.17,
					uuid: '1488d41a-646f-4d95-86a1-319e633e893d'
				}
			],
			[
				TIME_FILTER_OPTION.LAST_YEAR,
				{
					values: [
						{
							name: '',
							values: {
								'1547424000000': 0.03,
								'1548028800000': 0.08,
								'1548633600000': 0.07,
								'1549238400000': 0.06,
								'1549843200000': 0.06,
								'1550448000000': 0.07,
								'1551052800000': 0.09,
								'1551657600000': 0.07,
								'1552262400000': 0.04,
								'1552867200000': 0.04,
								'1553472000000': 0.04,
								'1554076800000': 0.04,
								'1554681600000': 0.05,
								'1555286400000': 0.04,
								'1555891200000': 0.04,
								'1556496000000': 0.05,
								'1557100800000': 0.05,
								'1557705600000': 0.06,
								'1558310400000': 0.05,
								'1558915200000': 0.05,
								'1559520000000': 0.05,
								'1560124800000': 0.06,
								'1560729600000': 0.06,
								'1561334400000': 0.05,
								'1561939200000': 0.05,
								'1562544000000': 0.06,
								'1563148800000': 0.05,
								'1563753600000': 0.05,
								'1564358400000': 0.05,
								'1564963200000': 0.04,
								'1565568000000': 0.05,
								'1566172800000': 0.05,
								'1566777600000': 0.06,
								'1567382400000': 0.05,
								'1567987200000': 0.05,
								'1568592000000': 0.05,
								'1569196800000': 0.05,
								'1569801600000': 0.06,
								'1570406400000': 0.05,
								'1571011200000': 0.05,
								'1571616000000': 0.05,
								'1572220800000': 0.05,
								'1572825600000': 0.05,
								'1573430400000': 0.05,
								'1574035200000': 0.05,
								'1574640000000': 0.06,
								'1575244800000': 0.06,
								'1575849600000': 0.06,
								'1576454400000': 0.06,
								'1577059200000': 0.06,
								'1577664000000': 0.05,
								'1578268800000': 0.04,
								'1578873600000': 0.05
							}
						}
					],
					total: 0.05,
					trend: 0,
					uuid: '1488d41a-646f-4d95-86a1-319e633e893d'
				}
			]
		])
	]
]);
