import { Card, ErrorBoundaryComponent } from '@bringg/react-components';
import { MetricType } from '@bringg/react-components/dist/components/metric-counter/metric-counter';
import { COMPONENT_TYPES } from '@bringg/types';
import { Col, Row } from 'antd';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import LineChart from '../../components/widget-types/line-chart/line-chart';
import PieDonut from '../../components/widget-types/pie-donut/pie-donut';
import NonTimeStackedSegmentBar from '../../components/widget-types/segment-bar/non-time-stacked-segment-bar';
import StackedSegmentBar from '../../components/widget-types/segment-bar/stacked-segment-bar';
import UnStackedSegmentBar from '../../components/widget-types/segment-bar/unstacked-segment-bar';
import { useInject } from '../../recipes/use-inject';
import { useStore } from '../../recipes/use-store';
import { layoutStoreMap } from '../../stores/helpers/store-maps';
import WidgetWrapper from './widget-wrapper/widget-wrapper';

export const SERVER_TYPE_TO_METRIC_TYPE = {
	time: MetricType.DURATION,
	number: MetricType.NUMBER,
	money: MetricType.MONEY,
	percentage: MetricType.CONVERT_TO_PERCENTAGE
};

const SUPPORTED_WIDGETS = [
	COMPONENT_TYPES.BIG_NUMBER,
	COMPONENT_TYPES.TIME_GRAPH,
	COMPONENT_TYPES.STACKED_SEGMENT_BAR,
	COMPONENT_TYPES.PIE,
	COMPONENT_TYPES.UNSTACKED_SEGMENT_BAR,
	COMPONENT_TYPES.NON_TIME_BASED_STACKED_SEGMENT_BAR,
	COMPONENT_TYPES.MULTIPLE_QUERIES
];

export const COMPONENT_TYPE_TO_COMPONENT = {
	[COMPONENT_TYPES.TIME_GRAPH]: LineChart,
	[COMPONENT_TYPES.STACKED_SEGMENT_BAR]: StackedSegmentBar,
	[COMPONENT_TYPES.PIE]: PieDonut,
	[COMPONENT_TYPES.UNSTACKED_SEGMENT_BAR]: UnStackedSegmentBar,
	[COMPONENT_TYPES.NON_TIME_BASED_STACKED_SEGMENT_BAR]: NonTimeStackedSegmentBar
};

export const Widgets = () => {
	const { uiStore } = useStore();
	const { layoutStore } = useInject(layoutStoreMap);

	return useObserver(() => (
		<>
			{uiStore.viewStore.isDemoUser && <h3 className="demo-label">----- Demo Data -----</h3>}
			<Row className="bi-widgets" gutter={24}>
				{!_isEmpty(_get(layoutStore, 'layout.reports_widgets')) &&
					layoutStore.layout.visibleWidgets
						.filter(widget => SUPPORTED_WIDGETS.includes(widget.type))
						.map(widget => (
							<Col key={widget.id} xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
								<Card className="widget-card">
									<ErrorBoundaryComponent title={widget.title}>
										<div>
											<WidgetWrapper key={widget.uuid} widget={widget} />
										</div>
									</ErrorBoundaryComponent>
								</Card>
							</Col>
						))}
			</Row>
		</>
	));
};

export default Widgets;
