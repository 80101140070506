import React from 'react';
import { useTranslation } from 'react-i18next';

export const Timezone = () => {
	const { t } = useTranslation();

	return (
		<div className="timezone">
			<div className="time-zone-title">{t('TIMEZONE')}</div>
			{Intl.DateTimeFormat().resolvedOptions().timeZone}
		</div>
	);
};

export default Timezone;
