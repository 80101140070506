import { notification } from 'antd';

notification.config({
	placement: 'bottomRight',
	duration: 2
});

class Notification {
	success = (message?: string, description?: string): void => {
		return notification.success({ message, description });
	};

	info = (message?: string, description?: string): void => {
		return notification.info({ message, description });
	};

	warn = (message?: string, description?: string): void => {
		return notification.warn({ message, description });
	};

	error = (message?: string, description?: string): void => {
		return notification.error({ message, description });
	};
}

export default new Notification();
