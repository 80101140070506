export default class AsyncRequestQueue {
	private limit: number;
	private doneRequest: number;
	private waitTime: number;
	private inProgress: number = 0;

	constructor(limit: number = 10, waitTime: number = 100) {
		this.limit = limit;
		this.waitTime = waitTime;
	}

	async executeRequest(request: Promise<any>) {
		if (this.canExecute()) {
			return this.handleRequest(request);
		} else {
			return this.waitForSlot(request);
		}
	}

	getFinishedRequestCount() {
		return this.doneRequest;
	}

	private async handleRequest(request: Promise<any>) {
		this.inProgress++;
		const response = await request;
		this.inProgress--;

		this.doneRequest++;

		return response;
	}

	private async waitForSlot(request: Promise<any>) {
		const promise = new Promise((resolve, reject) => {
			setTimeout(async () => {
				const response = await this.executeRequest(request);

				resolve(response);
			}, this.waitTime);
		});

		return await promise;
	}

	private canExecute() {
		return this.inProgress < this.limit;
	}
}
