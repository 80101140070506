import { types } from 'mobx-state-tree';

import { DataStore, DataStoreModel } from './data/data-store';
import { UiStore, UiStoreModel } from './ui/ui-store';

export interface RootStoreModel {
	uiStore: UiStoreModel;
	dataStore: DataStoreModel;
}

export const RootStore = types
	.model('RootStore', {
		uiStore: types.maybeNull(UiStore),
		dataStore: types.maybeNull(DataStore)
	})
	.actions(self => ({
		afterCreate() {
			const uiStore = UiStore.create({
				authStore: null,
				viewStore: null
			});
			const dataStore = DataStore.create({
				layoutStore: null
			});

			self.uiStore = uiStore;
			self.dataStore = dataStore;
		}
	}));
