import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../recipes/use-store';

export const DataGenerated = () => {
	const { t } = useTranslation();
	const {
		uiStore: { viewStore }
	} = useStore();

	return useObserver(() => {
		const { lastUpdateTimestamp } = viewStore;

		return (
			<div className="data-generated">
				<div className="data-generated-title">{t('DATA_GENERATED_ON')}</div>
				<div className="data-generated-actions">
					<span className="data-generated-timestamp">
						{lastUpdateTimestamp ? new Date(lastUpdateTimestamp).toLocaleString() : null}
					</span>
				</div>
			</div>
		);
	});
};
