import { FILTER_TYPES } from '@bringg/types';
import { FilterResponse, FiltersResponse } from '@bringg/types';
import _forEach from 'lodash/forEach';
import _isNill from 'lodash/isNil';
import { flow, getEnv, getRoot, Instance, types } from 'mobx-state-tree';

import { RootStoreEnv } from '../helpers/create-store';
import { Filter } from '../models/filter';
import { RootStoreModel } from '../root-store';

export interface FilterStoreModel extends Instance<typeof FilterStore> {}

export const FilterStore = types
	.model('FilterStore', {
		filters: types.optional(types.array(Filter), []),
		isLoading: false
	})
	.actions(self => {
		const handleFetchedFilters = (filters: FilterResponse[]): void => {
			self.filters.clear();

			const viewStore = getRoot<RootStoreModel>(self).uiStore.viewStore;

			_forEach(filters, ({ filter_type, values }) => {
				const selectedFilterIdByType = viewStore.selectedFilters.get(filter_type);

				if (!_isNill(selectedFilterIdByType)) {
					const inValues = values.some(({ id }) => Number(id) === selectedFilterIdByType);

					if (!inValues || !values.length) {
						viewStore.updateSelectedFilter(filter_type, null);
					}
				}

				if (filter_type && values?.length) {
					self.filters.push(
						Filter.create({
							filter_type,
							values
						})
					);
				}
			});
		};

		const fetch = flow(function* () {
			const filterTypes = getRoot<RootStoreModel>(self).dataStore.layoutStore.layout.filter_types;

			const { selectedTimeFilter, selectedMerchantId, selectedTeamId } = getRoot<RootStoreModel>(
				self
			).uiStore.viewStore;

			if (filterTypes.length) {
				self.isLoading = true;

				const dashboardSdkService = getEnv<RootStoreEnv>(self).dashboardSdk;
				const query = {
					filters: filterTypes as FILTER_TYPES[],
					time_range: {
						from: selectedTimeFilter.from,
						to: selectedTimeFilter.to
					}
				};

				if (selectedMerchantId) query['merchant_ids'] = [selectedMerchantId];
				if (selectedTeamId) query['team_ids'] = [selectedTeamId];

				const response: FiltersResponse = yield dashboardSdkService.sdk.reports.getFilters(query);

				self.isLoading = false;

				response.success && handleFetchedFilters(response.filters);
			}
		});

		return {
			fetch
		};
	});
