import i18next, { InitOptions, TFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const DEFAULT_LOAD_PATH = '/locales/{{lng}}.json';

export const initI18n = async (): Promise<TFunction> => {
	const options: InitOptions = {
		fallbackLng: 'en',
		debug: false,
		ns: 'default',
		lowerCaseLng: true,
		interpolation: {
			escapeValue: false
		},
		react: {
			wait: true,
			useSuspense: false
		},
		fallbackNS: 'default'
	};

	options.backend = {
		loadPath: DEFAULT_LOAD_PATH
	};

	i18next.use(Backend);

	return await i18next.use(initReactI18next).use(LanguageDetector).init(options);
};

export default initI18n;
