import { Option, Select } from '@bringg/react-components';
import React from 'react';

export type DropdownProps = {
	items: any[];
	value: string | number;
	onSelect: (item: any) => void;
	title?: string;
	placeholder?: string;
	disabled: boolean;
	loading?: boolean;
};

export const Dropdown = ({ title, onSelect, items, value, placeholder, disabled, loading }: DropdownProps) => {
	const filterOption = (input, option) => {
		return option.children && option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
	};

	return (
		<div className="bi-dropdown">
			<div className="bi-dropdown-title">{title}</div>
			{items.length === 1 ? (
				<div className="bi-dropdown-single-item-name">{items[0].name}</div>
			) : (
				<Select
					disabled={disabled}
					style={{ width: 200 }} // for antd design reason needs to be here
					showSearch={true}
					filterOption={filterOption}
					dropdownMatchSelectWidth={false}
					className="bi-dropdown-select"
					size="small"
					value={value}
					loading={loading}
					onSelect={onSelect}
				>
					<Option key={null} className="bi-dropdown-option" id="test" value={null}>
						{placeholder}
					</Option>
					{items.map(item => (
						<Option key={item.id} className="bi-dropdown-option" value={item.id}>
							{item.name}
						</Option>
					))}
				</Select>
			)}
		</div>
	);
};
