import { MetricType } from '@bringg/react-components/dist/components/metric-counter/metric-counter';
import _map from 'lodash/map';
import { useObserver } from 'mobx-react-lite';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

import { getDefaultWidgetOptions } from '../../../features/widgets/widgets.config';
import { GroupingDataModel } from '../../../stores/models/grouping-data';
import { getCommonPrefix } from '../../widget-template/widget-utils';
import { responsiveOptions } from './pie-donut-utils';

interface Props {
	data: GroupingDataModel;
	metricType: MetricType;
}

const colorPalette = [
	'#2DC0FF',
	'#FFC700',
	'#BEC881',
	'#FBADDB',
	'#5B7FFF',
	'#BCEFDC',
	'#50CB5C',
	'#C9B0FF',
	'#DF518D',
	'#FF7E7E'
];

const getSortedData = (groupingData: GroupingDataModel) => {
	const total = Array.isArray(groupingData.total) ? groupingData.total[0] : (groupingData.total as number);

	const prefixToRemove = getCommonPrefix(groupingData);
	const data = _map(groupingData.valuesByName, (data, name) => {
		if (prefixToRemove) {
			name = name.replace(prefixToRemove, '');
		}
		const value = data as number;
		const percentage = calcPercentage(value, total);

		return {
			value,
			name: `${name} (${percentage}%)`
		};
	});

	return data.sort((a, b) =>
		isNaN(parseInt(a.name)) ? b.name.localeCompare(a.name) : parseInt(b.name) - parseInt(a.name)
	);
};

const calcPercentage = (value, total) => (total === 0 ? 0 : ((value / total) * 100).toFixed(2));

export const getOptions = (groupingData: GroupingDataModel, metricType: MetricType): any => {
	const sortedData = getSortedData(groupingData);
	const total = groupingData.total;

	return {
		...getDefaultWidgetOptions(metricType),
		labels: sortedData.map(({ name }) => name),
		colors: colorPalette,
		dataLabels: {
			enabled: true,
			style: {
				fontWeight: 'normal',
				fontSize: '10px'
			}
		},
		tooltip: {
			enabled: true,
			y: {
				formatter: val => `${calcPercentage(val, total)}%`,
				title: {
					formatter: seriesName => seriesName.split('(')[0]
				}
			}
		},
		legend: {
			float: false,
			position: 'right',
			width: 200
		},
		plotOptions: {
			pie: {
				donut: {
					size: '60%',
					labels: {
						show: true,
						value: {
							fontSize: '14px'
						},
						total: {
							show: true,
							fontSize: '14px'
						}
					}
				}
			}
		},
		responsive: responsiveOptions
	};
};

export const getSeries = (groupingData: GroupingDataModel): number[] => {
	const sortedData = getSortedData(groupingData);

	return _map(sortedData, data => data.value);
};

const PieDonut = ({ data, metricType }: Props) =>
	useObserver(() => {
		const options = getOptions(data, metricType);
		const series = getSeries(data);

		return (
			<div className="chart-item pie-donut">
				<ReactApexChart type="donut" options={options} series={series} />
			</div>
		);
	});

export default PieDonut;
