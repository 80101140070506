import { Spinner } from '@bringg/react-components';
import { MetricType } from '@bringg/react-components/dist/components/metric-counter/metric-counter';
import { COMPONENT_TYPES } from '@bringg/types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { useObserver } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { NoData } from '../../../components/no-data/no-data';
import NumberWidget from '../../../components/number-widget/number-widget';
import WidgetDetails from '../../../components/widget-details/widget-details';
import WidgetTemplate from '../../../components/widget-template/widget-template';
import { useStore } from '../../../recipes/use-store';
import { WidgetModel } from '../../../stores/models/widget/widget';
import { COMPONENT_TYPE_TO_COMPONENT, SERVER_TYPE_TO_METRIC_TYPE } from '../widgets';

interface WidgetWrapperProps {
	widget: WidgetModel;
}
const SIMPLE_NUMBER_COMPONENTS_WIDGETS = [COMPONENT_TYPES.BIG_NUMBER, COMPONENT_TYPES.MULTIPLE_QUERIES];

const WidgetWrapper: React.FC<WidgetWrapperProps> = ({ widget }) => {
	const {
		uiStore: { viewStore },
		dataStore: { merchantStore }
	} = useStore();
	const { ref, inView } = useInView({
		threshold: 0
	});

	useEffect(() => {
		if (
			inView &&
			widget.isLoading &&
			!_isEmpty(viewStore.selectedTimeFilter) &&
			!_isEmpty(viewStore.selectedCompareTimeFilter)
		) {
			widget.fetchData();
		}
	}, [
		widget,
		viewStore.selectedTimeFilter,
		viewStore.selectedTimeFilter.from,
		viewStore.selectedTimeFilter.to,
		viewStore.selectedCompareTimeFilter,
		viewStore.selectedCompareTimeFilter.from,
		viewStore.selectedCompareTimeFilter.to,
		viewStore.selectedTeamId,
		viewStore.getSelectedFilters,
		viewStore.selectedMerchantId,
		inView
	]);
	const convertServerTypeToMetricType = units => {
		if (Array.isArray(units)) {
			return _map(units, item => SERVER_TYPE_TO_METRIC_TYPE[item]);
		} else if (units in SERVER_TYPE_TO_METRIC_TYPE) {
			return SERVER_TYPE_TO_METRIC_TYPE[units];
		} else {
			return typeof units === 'string' && units !== ''
				? SERVER_TYPE_TO_METRIC_TYPE.money
				: SERVER_TYPE_TO_METRIC_TYPE[units];
		}
	};
	const getElement = () => {
		if (widget.isLoading) return <Spinner className="bi-spinner" />;

		if (widget.isEmptyData) {
			return (
				<NoData title={widget.title}>
					{widget.details_query && <WidgetDetails detailsQuery={widget.details_query} />}
				</NoData>
			);
		}

		const getCurrencySign = () => {
			if ((widget?.options?.units as string) in SERVER_TYPE_TO_METRIC_TYPE) {
				if (widget?.options?.units === 'money') {
					const merchantCurrency = merchantStore.merchantConfiguration?.price_currency;
					if (merchantCurrency) {
						try {
							return new Intl.NumberFormat('en-US', {
								style: 'currency',
								currency: merchantCurrency
							}).formatToParts()[0].value;
						} catch {
							console.warn('Wrong merchantCurrency format');
						}
					}
				}
			} else {
				return widget?.options?.units as string;
			}
		};

		const currencySign = getCurrencySign();
		const metricType = convertServerTypeToMetricType(_get(widget, 'options.units')) || MetricType.NUMBER;

		//targetType is used only when we use Multiple Query
		const targetType = SERVER_TYPE_TO_METRIC_TYPE[_get(widget, 'options.target_type')];

		if (SIMPLE_NUMBER_COMPONENTS_WIDGETS.includes(widget.type)) {
			return (
				<div>
					<NumberWidget widget={widget} metricType={metricType} targetType={targetType} />
				</div>
			);
		} else {
			const component = COMPONENT_TYPE_TO_COMPONENT[widget.type];

			if (component) {
				return (
					<WidgetTemplate
						widget={widget}
						WidgetComponent={component}
						metricType={metricType}
						currencySign={currencySign}
					/>
				);
			}
		}
	};

	return useObserver(() => {
		return <div ref={ref}>{getElement()}</div>;
	});
};

export default WidgetWrapper;
