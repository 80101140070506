import { FILTER_TYPES, ReportsFilter } from '@bringg/types';

export const getFilterTypes = (filters: ReportsFilter[]) => {
	// loop through filters, handle time_range and for the rest create a list
	let timeFilters: string[],
		filterTypes: string[] = [];

	filters.forEach(({ name, values }) => {
		if (name === FILTER_TYPES.TIME_RANGE) {
			timeFilters = values || [];
		} else {
			filterTypes.push(name);
		}
	});

	return { timeFilters, filterTypes };
};
