import { MetricType } from '@bringg/react-components/dist/components/metric-counter/metric-counter';

export const getDefaultWidgetOptions = (metricType: MetricType) => {
	return {
		chart: {
			fontFamily: 'Rubik',
			zoom: {
				enabled: false
			}
		},
		stroke: {
			width: 2
		},
		xaxis: {
			labels: {
				datetimeUTC: false,
				datetimeFormatter: {
					year: 'MMM dd ‘YY',
					month: 'MMM dd',
					day: 'MMM dd',
					hour: 'hh TT'
				}
			}
		},
		yaxis: {
			labels: {
				formatter: value => convertValue(value, metricType)
			}
		},
		colors: [
			'#0084FA',
			'#05DF8B',
			'#FEA619',
			'#FF3C55',
			'#6B53CA',
			'#83D0F5',
			'#F29FC5',
			'#007F33',
			'#FFDE39',
			'#8E2B14'
		],
		legend: {
			markers: {
				radius: 0,
				width: 'auto',
				height: 6
			},
			itemMargin: {
				horizontal: 5,
				vertical: 2
			}
		}
	};
};

const convertNumberValue = (value: number) => {
	if (value < 1000) {
		return value.toFixed(2);
	} else if (value > 1000000) {
		return `${+(value / 1000000).toFixed(2)}M`;
	}
	return value ?? 0;
};

const convertTimeValue = (value: number) => {
	const minutes: number = Math.floor((value || 0) / 60);
	const seconds: number = Math.floor((value || 0) % 60);

	return `${minutes > 0 ? minutes + 'm' : ''}${seconds > 0 ? seconds + 's' : ''}`;
};

const convertPercentageValue = (value: number) => {
	return `${((value || 0) * 100).toFixed()}%`;
};

export const convertValue = (value: number, metricType: MetricType) => {
	switch (metricType) {
		case MetricType.CONVERT_TO_PERCENTAGE:
			return convertPercentageValue(value);
		case MetricType.DURATION:
			return convertTimeValue(value);
		default:
			return convertNumberValue(value);
	}
};
