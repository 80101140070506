import moment from 'moment';

import { Fn } from '../../types/functions.type';
import { formatAsSystem } from '../../util/date.util';

export enum TIME_FILTER_OPTION {
	LAST_24H = 'LAST_24H', // TODO: should be removed
	TODAY = 'TODAY',
	YESTERDAY = 'YESTERDAY',
	LAST_7D = 'LAST_7D',
	LAST_MONTH = 'LAST_MONTH',
	LAST_YEAR = 'LAST_YEAR' // TODO: should be removed
}

export interface TimeFilterInterface {
	key: TIME_FILTER_OPTION;
	title: string;
	getDescription: Fn<void, string>;
	getValue: Fn<void, { from: number; to: number }>;
}

// TODO: this should be removed
const HOUR_IN_MILLIS = 60 * 60 * 1000;
const DAY_IN_MILLIS = 24 * HOUR_IN_MILLIS;
export const TIME_FILTER_TO_VALUE = {
	[TIME_FILTER_OPTION.LAST_24H]: { title: '24h', value: DAY_IN_MILLIS },
	[TIME_FILTER_OPTION.LAST_7D]: { title: '7d', value: 7 * DAY_IN_MILLIS },
	[TIME_FILTER_OPTION.LAST_MONTH]: { title: '1m', value: 30 * DAY_IN_MILLIS },
	[TIME_FILTER_OPTION.LAST_YEAR]: { title: '1y', value: 365 * DAY_IN_MILLIS }
};

const START_OF_YEAR_MOMENT = moment().startOf('y');

export const TIME_FILTERS_LIST: TimeFilterInterface[] = [
	{
		key: TIME_FILTER_OPTION.TODAY,
		title: 'Today',
		getValue: () => ({
			from: moment().startOf('d').valueOf(),
			to: moment().valueOf()
		}),
		getDescription: () => 'Filters data from the beginning of the delivery window/block until now.'
	},
	{
		key: TIME_FILTER_OPTION.YESTERDAY,
		title: 'Yesterday',
		getValue: () => ({
			from: moment().subtract(1, 'd').startOf('d').valueOf(),
			to: moment().subtract(1, 'd').valueOf()
		}),
		getDescription: () => 'Filters data from yesterday’s delivery windows/blocks.'
	},
	{
		key: TIME_FILTER_OPTION.LAST_MONTH,
		title: 'This month',
		getValue: () => ({
			from: moment().subtract(30, 'd').startOf('d').valueOf(),
			to: moment().valueOf()
		}),
		getDescription: () =>
			`Filters data from ${formatAsSystem(moment().startOf('month'))} to ${formatAsSystem(moment())}`
	},
	{
		key: TIME_FILTER_OPTION.LAST_YEAR,
		title: 'This year',
		getValue: () => ({
			from: START_OF_YEAR_MOMENT.valueOf(),
			to: moment().valueOf()
		}),
		getDescription: () => `Filters data from ${formatAsSystem(START_OF_YEAR_MOMENT)} to ${formatAsSystem(moment())}`
	}
];

export const PICKER_DATE_FORMAT = 'MMM dd, yyyy';
