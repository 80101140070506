import { Authentication, DashboardSdk } from '@bringg/frontend-utils';
import { Environment } from '@bringg/frontend-utils/dist/common/types';
import AirbrakeService from '@bringg/frontend-utils/dist/services/airbrake/airbrake';

import AsyncRequestQueue from '../../services/async-request-queue';
import { DemoData } from '../../services/demo-data';
import { RootStore, RootStoreModel } from '../root-store';

export type FakeAirbrake = {
	notify: () => void;
};

export type RootStoreEnv = {
	demoData: DemoData;
	dashboardSdk: DashboardSdk;
	authService: Authentication;
	envConfig: Environment;
	requestQueue: AsyncRequestQueue;
	airbrakeService: AirbrakeService | FakeAirbrake;
	usedAsIframe: boolean;
	isDev: boolean;
	isQaEnv: boolean;
};

export const createStore = (
	demoData: DemoData,
	dashboardSdk: DashboardSdk,
	authService: Authentication,
	envConfig: Environment,
	requestQueue: AsyncRequestQueue,
	airbrakeService: AirbrakeService | FakeAirbrake,
	usedAsIframe: boolean = false,
	isDev: boolean = false,
	isQaEnv: boolean = false
): RootStoreModel => {
	const env: RootStoreEnv = {
		demoData,
		dashboardSdk,
		authService,
		envConfig,
		requestQueue,
		airbrakeService,
		usedAsIframe,
		isDev,
		isQaEnv
	};

	return RootStore.create(
		{
			uiStore: null,
			dataStore: null
		},
		env
	);
};
