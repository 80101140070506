import { Button } from '@bringg/react-components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useInject } from '../../recipes/use-inject';
import { authStoreMap } from '../../stores/helpers/store-maps';

export const LogoutButton = () => {
	const { t } = useTranslation();
	const { authStore } = useInject(authStoreMap);

	return (
		<Button className="logout-button" onClick={authStore.logout}>
			{t('LOGOUT')}
		</Button>
	);
};
