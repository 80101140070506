import { MetricType } from '@bringg/react-components/dist/components/metric-counter/metric-counter';
import _isNaN from 'lodash/isNaN';
import { useObserver } from 'mobx-react-lite';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { getDefaultWidgetOptions } from '../../../features/widgets/widgets.config';
import { GroupingDataModel } from '../../../stores/models/grouping-data';
import { getSeries } from '../../widget-template/widget-utils';

interface Props {
	data: GroupingDataModel;
	metricType: MetricType;
	stacked: boolean;
	isDatetime: boolean;
}

export type SeriesItem = {
	name: string;
	data: number[];
};

export const getOptions = (
	groupingData: GroupingDataModel,
	metricType: MetricType,
	stacked,
	isDatetime: boolean,
	translation
): any => {
	const categories = groupingData.keys.map(dataItem =>
		isDatetime || !_isNaN(+dataItem) ? Number.parseInt(dataItem) : translation(dataItem)
	);
	const defaultOptions = getDefaultWidgetOptions(metricType);

	return {
		...defaultOptions,
		xaxis: {
			...defaultOptions.xaxis,
			...(isDatetime && { type: 'datetime' }),
			categories
		},
		dataLabels: {
			enabled: false
		},
		chart: {
			...defaultOptions.chart,
			stacked
		}
	};
};

const SegmentBar = ({ data, metricType, stacked, isDatetime }: Props) => {
	const { t } = useTranslation();

	const options = getOptions(data, metricType, stacked, isDatetime, t);
	const series = getSeries(data);

	return useObserver(() => (
		<div className="chart-item segment-bar">
			<ReactApexChart type="bar" options={options} series={series} />
		</div>
	));
};

export default SegmentBar;
