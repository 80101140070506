import { Instance, types } from 'mobx-state-tree';

export type GroupingDataModel = Instance<typeof GroupingData>;

export const GroupingData = types.model({
	keys: types.array(types.string),
	grouping: types.maybeNull(types.string),
	total: types.maybeNull(types.union(types.number, types.array(types.number))),
	valuesByName: types.frozen<Record<string, number[] | number>>()
});
