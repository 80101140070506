import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	title: string;
	children?: React.ReactNode | React.ReactNode[];
}

export const NoData = ({ title, children }: Props) => {
	const { t } = useTranslation();

	return (
		<div className="no-data">
			<span className="no-data-title">{t(title)}</span>
			<span className="no-data-text">{t('DATA_EMPTY')}</span>
			{children && <div className="children">{children}</div>}
		</div>
	);
};
