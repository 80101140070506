import { requestCredentials } from '@bringg/frontend-utils';
import { LoginResponse } from '@bringg/react-components/dist/components/login/login';
import { MerchantData } from '@bringg/types';
import { reaction } from 'mobx';
import { addDisposer, flow, getEnv, Instance, types } from 'mobx-state-tree';

import { RootStoreEnv } from '../helpers/create-store';

export interface AuthStoreModel extends Instance<typeof AuthStore> {}

export const AuthStore = types
	.model('AuthStore', {
		isLoggedIn: types.optional(types.boolean, false),
		reportUuid: types.maybeNull(types.string)
	})
	.actions(self => {
		const setReportUuid = (uuid: string) => {
			self.reportUuid = uuid;
		};
		const redirectAfterLogin = flow(function* () {
			const env = getEnv<RootStoreEnv>(self);
			const url = yield env.dashboardSdk.sdk.reports.getReportExecution(self.reportUuid);

			window.open(url, '_self', 'noopener');
		});

		return {
			setReportUuid,
			redirectAfterLogin
		};
	})
	.actions(self => {
		function setLogin(isLoggedIn: boolean) {
			self.isLoggedIn = isLoggedIn;
		}

		function attemptToLogin(
			email: string,
			password: string,
			selectedMerchant?: MerchantData,
			captcha?: string
		): Promise<LoginResponse> {
			return getEnv<RootStoreEnv>(self)
				.authService.attemptToLogin(email, password, selectedMerchant, captcha)
				.then(function (resp) {
					return resp;
				});
		}

		const initSdkFromEnvironment = flow(function* () {
			try {
				const { dashboardSdk } = getEnv<RootStoreEnv>(self);
				yield dashboardSdk.init();

				if (dashboardSdk.sdk) {
					setLogin(true);
				}
			} catch (e) {
				console.error('Failed to init sdk', e);
			}
		});

		const initSdkFromWindow = flow(function* () {
			const { isDev, isQaEnv } = getEnv<RootStoreEnv>(self);
			const ignoreOrigin = isDev || isQaEnv || false;

			try {
				const { region, token } = yield requestCredentials({ waitTime: 15000, ignoreOrigin });
				const { dashboardSdk, authService } = getEnv<RootStoreEnv>(self);
				yield dashboardSdk.loginWithAuthToken(region, token);

				if (dashboardSdk.sdk) {
					const session = dashboardSdk.sdk.session;
					setLogin(true);
					authService.storeCredentials(
						session.user.authentication_token,
						session.region,
						session.user.language
					);
				}
			} catch (e) {
				console.error('Failed to init sdk', e);
			}
		});

		const afterCreate = () => {
			if (window.location.search.includes('reportId=')) {
				const reportId = window.location.search.split('reportId=')[1];

				self.setReportUuid(reportId);
			}

			const redirectDisposer = reaction(
				() => self.isLoggedIn === true && self.reportUuid !== null,
				() => {
					self.redirectAfterLogin();
				}
			);

			addDisposer(self, redirectDisposer);
		};

		const logout = () => {
			const { dashboardSdk, authService } = getEnv<RootStoreEnv>(self);

			setLogin(false);
			authService.clearCredentials();
			dashboardSdk.destroy();
		};

		return {
			afterCreate,
			initSdkFromWindow,
			initSdkFromEnvironment,
			setLogin,
			attemptToLogin,
			logout
		};
	})
	.views(self => ({
		get recaptchaSiteKey() {
			return getEnv<RootStoreEnv>(self).envConfig.recaptcha_site_key;
		},
		get shouldLoadApp() {
			return self.isLoggedIn && !self.reportUuid;
		},
		get isUsedAsIframe() {
			return getEnv<RootStoreEnv>(self).usedAsIframe;
		}
	}));
