import React from 'react';

import { DateRanges, FromToDateRange } from '../../features/time-filters/time-filters.type';

interface Props {
	ranges: DateRanges;
	onRangeClick: (range: FromToDateRange, rangeKey: string) => void;
}

export const PickerRanges = ({ ranges, onRangeClick }: Props) => {
	return (
		<ul className="ant-picker-ranges">
			<li className="ant-picker-preset">
				{Object.entries(ranges).map(([key, value]) => (
					<span key={key} className="ant-tag ant-tag-blue" onClick={() => onRangeClick(value.range, key)}>
						{key}
					</span>
				))}
			</li>
		</ul>
	);
};
